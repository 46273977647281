import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

// import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import { useSelector} from "react-redux";
import LoginRoutes from "./LoginRoutes";
// import { saveLogoutInLocalStorage } from "../helpers/constants";
  
const MyRoutes= () => {
    const token=useSelector(state => state.jwt );
  return (
    <Router>
      {token!==null ? <MainRoutes/>  : <LoginRoutes/>}

    
    </Router>
  );
}
export default MyRoutes;