import React, { useState ,useEffect} from "react";
import {useSelector, useDispatch } from "react-redux";
import { Card,CardContent, Grid ,Typography,Button,TableContainer,Table
  ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
  Paper,Select,MenuItem,FormControl,InputLabel } from "@mui/material";
import {getEndPoint} from "../../helpers/constants";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import "./personnel.css"
import {logout} from "../../redux/auth/authactions"
import { useNavigate } from "react-router-dom";
import {saveLogoutInLocalStorage,generateArrayOfMonths,generateArrayOfYears,generateDays} from "../../helpers/constants";
import { ImportantDevices } from "@mui/icons-material";




//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";


const View= () => {  
    //Agent Identification
    const decodedJWT=useSelector(state => state.decodedJWT );


    const [users,setUsers]=useState([]);
    const [usersCount,setUsersCount]=useState(0);
    // User Data


    //
    const [page, setPage] = useState(0);
    const [search,setSearch]= useState("");
    const [timer,setTimer]=useState(null);
  


    const dispatch=useDispatch();
    const jwt=useSelector(state => state.jwt);
    const history=useNavigate();
    const headers = { headers: {
      "Authorization" : `Bearer ${jwt}`,

    } }

      const handleChangePage = (event,newPage) => 
      {
          setPage(newPage);
      };


    const freeTokenFromSessionStorage = () => {
      dispatch(logout);
      saveLogoutInLocalStorage();
      history("/login");
    };
    const loadUsers= async() => {
   
      console.log("decodedJWT");
      console.log(decodedJWT);
      const endpoint=getEndPoint("/usersPage?page="+page+"&userId="+search);
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
   
      });
      setUsers(old => data.data.content);
      setUsersCount(old => data.data.totalElements);

    }



 
    useEffect(() => {
      if(timer!=null)
      {
        console.log("Time Out Clear")
        clearTimeout(timer);
      }
      console.log("set timer called");
      const myTimer= setTimeout(function() {
        console.log("users loaded from Timer");
        loadUsers(search)
      },1000);
      setTimer(old => myTimer);


    },[search]);
    useEffect(()=> {
      loadUsers(null);

    },[page]);
    
    return (
      <div>
 
  {/* search Part */}
    <Card className="searchCard" variant="outlined">
        <CardContent>

        <p  className="cornerText">Recherche Global  </p>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Recherche :
            </Typography>
            <MyInput value={search} onChange={(event) =>{setSearch((old)=> (event.target.value))}} 
            placeholder="Recherche" 
            />
              </Grid>
          
          </Grid>
        </CardContent>
    </Card>


    <br/>

    {/* Table Part !  */}
    <TableContainer className="tableContainerStyle"  component={Paper}>

        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            
          <TableHead>
            <TableRow>
              <TableCell className="filtertitle" align="left">id</TableCell>
              <TableCell className="filtertitle" align="left">name</TableCell>
              <TableCell className="filtertitle" align="left">gender</TableCell>
             
              <TableCell className="filtertitle" align="left">phone Number</TableCell>
              <TableCell className="filtertitle" align="left">Date Of Birth</TableCell>
              <TableCell className="filtertitle" align="left">Created At</TableCell>
              <TableCell className="filtertitle" align="left">Balance</TableCell>
            </TableRow>
          </TableHead>
     
          <TableBody>
          {users.map(user => (
 <TableRow
 // key={order.id}
  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
  <TableCell component="th" scope="row" align="left">{user.id}</TableCell>
  <TableCell align="left">{user.name}</TableCell>
  <TableCell  align="left">{user.gender}</TableCell>

  <TableCell align="left">{user.phoneNumber}</TableCell>
  <TableCell align="left">{user.dateOfBirth}</TableCell>
    <TableCell align="left">{user.createdAt}</TableCell>

    <TableCell align="left">{user.solde}</TableCell>
   

</TableRow>
    ))}
             
          </TableBody>
          <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  colSpan={3}
                  count={
                    usersCount
                    }
                  rowsPerPage={10}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
        </Table>
       
      </TableContainer>

      </div>
        
        );
 };

 export default View;