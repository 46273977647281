import {Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Button,Slide,
  Card,CardContent, Grid ,Typography,TableContainer,Table
  ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
  Paper,Select,MenuItem,FormControl,InputLabel } from "@mui/material";
import InputUnstyled from '@mui/base/InputUnstyled';
import React, { useState,useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import {saveLogoutInLocalStorage,generateArrayOfMonths,generateArrayOfYears,generateDays} from "../../../../helpers/constants";
import { styled } from '@mui/system';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const UpdateModal=({open,handleClose,agent,handleUpdate,locals}) => {

  const [localid,setLocalId] = useState("");
  const [name,setName] = useState("");
  const [phone,setPhone] = useState("");
  const [address,setAddress] = useState("");
  const [birthday,setBirthday] = useState({
    day:0,
    month:0,
    year:0,
  });
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [role,setRole] = useState("");

  const [isActive,setIsActive]=useState(false);
  const changeStatusFilter=(isActiveProp)=>
  {
    setIsActive((old) => isActiveProp);
  }
  let date=null;
 

 useEffect(
   () => {
     date=new Date(agent.birthday);
     console.log("MY date :"+date);
     console.log(agent);

    setLocalId();
    setName(agent.name);
    setPhone(agent.phone);
    setAddress(agent.address);
    setBirthday(
      {
        day:date.getDate(),
        month:date.getMonth()+1,
        year:date.getFullYear(),
      }
    );
    setEmail(agent.email);
    setRole(agent.role);
    setLocalId(agent.localid);
    setIsActive(agent.enabled);

   },
   [agent]
 );


  const setYear=(value) =>
    {
      setBirthday((old) => {
            return {
              ...old,
              year:value,
            }
      });

    };
    const setMonth=(value) =>
    {
      setBirthday((old) => {
            return {
              ...old,
              month:value,
            }
      });

    };
    const setDay=(value) =>
    {
      setBirthday((old) => {
            return {
              ...old,
              day:value,
            }
      });

    };
    const mydateToString=()=>{
      return birthday.year+"-"+birthday.month+"-"+birthday.day;

    }
 
  
    const updatedData=() => {

      return {
      
         localid:localid,
         name:name,
         phone:phone,
         address:address,
         birthday: new Date(mydateToString()),
         email:email,
         enabled:isActive,
         password:password,
         role:role,
        

      }
    }
 
  // const freeTokenFromSessionStorage = () => {
  //   dispatch(logout);
  //   saveLogoutInLocalStorage();
  //   history("/login");
  // };
    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle className="cornerText"> Modifier Agent : {agent.cin} </DialogTitle>
        <DialogContent id="dialogContent" >
          <Grid style={{padding:10}} container spacing={1}>
              <Grid item xs={12} md={6}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              NomPrenom :
            </Typography>
            <InputUnstyled value={name} onChange={(event) => setName(event.target.value)} 
            placeholder="NomPrenom" 
            components={{ Input: StyledInputElement }}/>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Email :
            </Typography>
            <InputUnstyled value={email} onChange={(event) =>{setEmail(event.target.value)}} 
            placeholder="Email" 
            components={{ Input: StyledInputElement }}/>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography  className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Mot de passe :
            </Typography>
            <InputUnstyled value={password} onChange={(event) =>{setPassword(event.target.value)}} 
            placeholder="Mot de passe" 
            components={{ Input: StyledInputElement }}/>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Numéro de téléphone :
            </Typography>
            <InputUnstyled value={phone} onChange={(event) =>{setPhone(event.target.value)}} 
            placeholder="Numéro de téléphone" 
            components={{ Input: StyledInputElement }}/>
              </Grid>
              <Grid item xs={12} md={6}>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Date d'anniversaire :
            </Typography>
            <Grid style={{width:345}} container spacing={1}>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Année</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Année"
                  displayEmpty
                  value={birthday.year}
                  className="birthdayselectStyle"
                  onChange={(event)=> setYear(event.target.value)}
                >
                  {generateArrayOfYears().map(year => (
            
                    <MenuItem key={year} value={year}>{year}</MenuItem>

                  ))}
                 
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Mois</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Mois"
                  displayEmpty
                  value={birthday.month}
                  className="birthdayselectStyle"
                
                  onChange={(event)=> setMonth(event.target.value)}
                >
                  {generateArrayOfMonths().map((month) => (
        
                    <MenuItem key={month.number} value={month.number}>{month.name}</MenuItem>

                  ))}
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Jour</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Jour"
                  className="birthdayselectStyle"
                  displayEmpty
                  value={birthday.day}
                  onChange={(event)=> setDay(event.target.value)}
                >
                  {generateDays().map((daynNumber) => (
        
        <MenuItem key={daynNumber} value={daynNumber}>{daynNumber}</MenuItem>

      ))}
                </Select>
              </FormControl>
              </Grid>
            </Grid>
       
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Adresse :
            </Typography>
            <InputUnstyled value={address} onChange={(event) =>{setAddress(event.target.value)}} 
            placeholder="Adresse" 
            components={{ Input: StyledInputElement }}/>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Local ID :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Role"
                  displayEmpty
                  value={localid}
                  className="selectStyle"
                  onChange={(event)=> setLocalId(old => event.target.value)}
                >
                  {locals.map(local =>(
                    <MenuItem value={local.id}>{local.id}</MenuItem>
                  )
                    )}
              
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Role :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Role"
                  displayEmpty
                  value={role}
                  className="selectStyle"
                  onChange={(event)=> setRole(old => event.target.value)}
                >
                 <MenuItem value={"SuperAdmin"}>SuperAdmin</MenuItem>
                    <MenuItem value={"Admin"}>Admin</MenuItem>
                    <MenuItem value={"Agent"}>Agent</MenuItem>
                    <MenuItem value={"Comptable"}>Comptable</MenuItem>
                    <MenuItem value={"DeliveryMan"}>Delivery Man</MenuItem>
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Est Activé  :
            </Typography>
              <Grid id="isActiveContainer" container spacing={0}>
                <Grid item md={6} xs={6}>
                  {isActive ? 
                  <Button onClick={() => {changeStatusFilter(true)}} id="completedButton">Activé</Button>
                  :
                  <Button onClick={() => {changeStatusFilter(true)}} id="completedButtonDisabled">Activé</Button>
                }
                </Grid>
                <Grid item md={6} xs={6}>
                {!isActive ? 
                  <Button onClick={() => {changeStatusFilter(false)}} id="failedButton">Désactivé</Button>
                  :
                  <Button onClick={() => {changeStatusFilter(false)}} id="failedButtonDisabled">Désactivé</Button>
                }
               
                </Grid>
                </Grid>
              </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
          <Button id="modifierButton" onClick={
           () => {
            handleUpdate({
              idAgent:agent.cin,
              data:updatedData(),
            });
           }
            }>Modifier</Button>
        </DialogActions>
      </Dialog>
    )
};
const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  600: '#0072E5',
};
const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};
const StyledInputElement = styled('input')(
  ({ theme }) => `
  width: 320px;
  height:38px;
  font-size: 1rem;
 
  font-weight:  500;
  line-height: 1.5;
  color: #3b3b3b;
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 5px;
  padding: 8px 8px;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }
`,
);
export default UpdateModal;
