

import jwt_decode from 'jwt-decode';
import imageCompression from 'browser-image-compression';

//ec2-15-188-50-161.eu-west-3.compute.amazonaws.com
//http://ec2-15-188-50-161.eu-west-3.compute.amazonaws.com
//http://15.188.50.161:80
//http://localhost:80
//https://engine.atwabassalam.com
//ec2-15-188-50-161.eu-west-3.compute.amazonaws.com:80

var backendEndPoint="http://13.37.105.121:80";
var backendDomainName="http://engine.gogainz.ma";
var backendDomainNameHTTPS="https://engine.gogainz.ma";
var localEndPoint="http://localhost:80";
var localEndPointHTTPS="https://localhost";


export const  getDomain=() =>
{
    return backendDomainNameHTTPS;
    // return localEndPoint;
}
export const getImageURL=(imageName)=>{
    return getDomain()+"/images/"+imageName;

}
export const getEndPoint= (url) =>
{
    return getDomain()+url;
}
export const getNewsImageUrl=(imageName) => {
    return getDomain()+"/images/newsImages/"+imageName;
}
export const getFoodImageURL= (imageName)=> {
    return getDomain()+"/images/foodImages/"+imageName;
}
export const getMealImageURL= (imageName)=> {
    return getDomain()+"/images/mealImages/"+imageName;
}
export const getSellPaymentImageURL= (imageName) => {
    return getDomain()+"/images/sellsPayments/"+imageName;
}
export const getInvoiceImageURL= (imageName) => {
    return getDomain()+"/images/facturesBons/"+imageName+".png";
}
export const getMarketPaymentImageURL= (imageName) => {
    return getDomain()+"/images/marketsPayments/"+imageName;
}
export const getPurchasePaymentImageURL= (imageName) => {
    return getDomain()+"/images/purchasesPayments/"+imageName;
}

export const saveLogoutInLocalStorage = () => {
    const data = JSON.parse(localStorage.getItem("storeState"));
    const updateddata={
        ...data,
        jwt:null,
        decodedJWT:null,
    };
    localStorage.setItem("storeState",JSON.stringify(updateddata));
    console.log("local storage :")
    console.log(JSON.parse(localStorage.getItem("storeState")));
  };
export const saveLoginInLocalStorage = (givenjwt) => {
    const data = JSON.parse(localStorage.getItem("storeState"));
    const updateddata={
        ...data,
        jwt:givenjwt,
        decodedJWT:jwt_decode(givenjwt),
    };
    localStorage.setItem("storeState",JSON.stringify(updateddata));
    console.log("local storage :")
    console.log(JSON.parse(localStorage.getItem("storeState")));
  };
  export const generateArrayOfYears=() =>{
    var max=new Date().getFullYear();
    var min=max-70;
    var years =[]
    for(let i=max;i>=min;i--)
    {
        years.push(i);
    }
    return years;
} 
export const generateArrayOfYearsForward=() =>{
    var min=new Date().getFullYear();
    var max=min+10;
    var years =[];
    for(let i=max;i>=min;i--)
    {
        years.push(i);
    }
    return years;
}
export const generateArrayOfMonths=()=>([
    {number:1,name:"janvier"},
    {number:2,name:"février"},
    {number:3,name:"mars"},
    {number:4,name:"avril"},
    {number:5,name:"mai"},
    {number:6,name:"juin"},
    {number:7,name:"juillet"},
    {number:8,name:"août"},
    {number:9,name:"septembre"},
    {number:10,name:"octobre"},
    {number:11,name:"novembre"},
    {number:12,name:"décembre "},

]);
export const generateDays=()=>
{
    var myarray=[];
    for(let i=1;i<=31;i++)
    {
        myarray.push(i);
    }
    return myarray;

}
export const generateCookingFactors=()=>
{
    var myarray=[
        0.1,
        0.15,
        0.2,
        0.25,
        0.3,
        0.35,
        0.4,
        0.45,
        0.5,
        0.55,
        0.6,
        0.65,
        0.7,
        0.75,
        0.8,
        0.85,
        0.9,
        0.95,
        1,
        1.05,
        1.1,
        1.15,
        1.2,
        1.25,
        1.3,
        1.35,
        1.4,
        1.45,
        1.5,
        1.55,
        1.6,
        1.65,
        1.7,
        1.75,
        1.8,
        1.85,
        1.9,
        1.95,
        2,
        2.05,
        2.1,
        2.15,
        2.2,
        2.25,
        2.3,
        2.35,
        2.4,
        2.45,
        2.5,
        2.55,
        2.6,
        2.65,
        2.7,
        2.75,
        2.8,
        2.85,
        2.9,
        2.95,
        3,
        3.05,
        3.1,
        3.15,
        3.2,
        3.25,
        3.3,
        3.35,
        3.4,
        3.45,
        3.5,
        3.55,
        3.6,
        3.65,
        3.7,
        3.75,
        3.8,
        3.85,
        3.9,
        3.95,
        4
    ];
    return myarray;
}
//Classes
export class DecodedJwt {
    constructor(Role,exp,iat,iss,localId,sub){
        this.Role=Role;
        this.exp=exp;
        this.iat=iat;
        this.iss=iss;
        this.localId=localId;
        this.sub=sub;
    }

    
}
export const getCompressedImage= async(imageFile) =>
{
    const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1920
      }
      try {
        const compressedFile = await imageCompression(imageFile, options);
        console.log(compressedFile.size/1024/1024);
        return compressedFile;
      } catch (error) {
        console.log(error);
      }
}