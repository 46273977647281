import {Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Button,Slide,
  Card,CardContent, Grid ,Typography,TableContainer,Table
  ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
  Paper,Select,MenuItem,FormControl,InputLabel } from "@mui/material";
import InputUnstyled from '@mui/base/InputUnstyled';
import React, { useState,useEffect } from "react";
import "./index.css";




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const RejectConfModal=({handleReject,handleClose,order,open}) => {

  const [myOrder,setMyOrder]=useState({});


 

 

 useEffect(
   () => {

 
    setMyOrder(old => order);


   },
   [order]
 );



 
  
 
  // const freeTokenFromSessionStorage = () => {
  //   dispatch(logout);
  //   saveLogoutInLocalStorage();
  //   history("/login");
  // };
    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullScreen="true"

      
      >
        <DialogTitle className="cornerText"> Order Id : {myOrder.id} </DialogTitle>
        <DialogContent className="orderExplorContainer" >
         <div>Do you confirm deleting the order : {myOrder.id}</div>
  
        </DialogContent>
        <DialogActions>
          <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
          <Button id="fermerButton" onClick={handleReject}>Confirm</Button>
    
        </DialogActions>
      </Dialog>
    )
};

export default RejectConfModal;
