import React, { useState } from "react";
import axios from "axios";
import {getEndPoint,saveLoginInLocalStorage} from "../helpers/constants";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ReactComponent as LogoSvg } from "../assets/logo.svg";
import Alert from "@mui/material/Alert";

import logo from "../assets/logo2.png";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch,useSelector } from "react-redux";
import "./LoginCss.css"
import {authenticate} from "../redux/auth/authactions";
const theme = createTheme();

theme.typography.h2 = {
  fontSize: "2.5em !important",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.7em !important",
  },
};

const LoginView= () => {
  const history = useNavigate();
  const dispatch=useDispatch();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();



  const handleFormLogin = async (event) => {
    const endpoint=getEndPoint("/authenticate")
    const data = await axios.post(
      endpoint,
       {
      "email" :username,
      "password" :password,
      },  
      );
  if(data.data!=null )
  {
    console.log("response :");
    console.log(data.data);
    dispatch(authenticate(data.data.jwt));
    saveLoginInLocalStorage(data.data.jwt);
    history("/agents");
  }
  
  };

     return (
    <Grid className="Global_container" container spacing={0}>
      <Grid item md={1} display={{ xs: "none", md: "block" }}>
       
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={10}
        direction="row"
        alignItems="center"
        justifyContent="center"
        className="Grid_cards_container"
      >
        {/* <Box
          item
          component={Grid}
          className="mobile_upper_content"

          //style={{ display: matchesMD ? "" : "none" }}
        >
          <LogoSvg
            className="LogoSvg"
            style={{ marginBottom: "5vh" }}
          />
          <Typography style={{ color: "white", fontSize: "1.8rem" }}>
            Content de vous revoir !
          </Typography>
        </Box> */}
        <Box
          component={Grid}
          className="left_card"
          item
          md={4}
          display={{ xs: "none", md: "block" }}
        >
           <div className="imageLogo2" >
          <img
            className="imageGrand-responsive"
            src={logo}
            style={{ width: "200px",height:"200px" }}
          />
        </div>
          <Box className="left_card_content">
            <Box>
              <Typography variant="h1" className="bigMsg">
                Content de vous revoir !
              </Typography>
              <Typography variant="h6" className="smallMSg">
                Connectez-vous avec vos informations personnelles et commencez à
                farfouiller votre tableau de bord !
              </Typography>
            </Box>
          </Box>
        </Box>

        <ThemeProvider theme={theme}>
          <Box
            component={Grid}
            className="right_card"
            item
            xs={12}
            md={8}
          >
            <Box className="right_card_content">
              <Box>
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h2"
                    className="right_bigMsg"
                    onClick={() => {
                      // setUsername("superadmin@gmail.com");
                      // setPassword("jigsaw123321");
                    }}
                  >
                    Connectez-vous
                  </Typography>
                </ThemeProvider>
              </Box>
              <Box className="form_login">
                {error && (
                  <Alert severity="error" style={{ marginBottom: "2vh" }}>
                    {error}
                  </Alert>
                )}

                <FormControl>
                  <TextField
                    className="form_input_field"
                    label="Client ID"
                    variant="outlined"
                    fullWidth
                    value={username}
                    onChange={(username) => {
                      setUsername(username.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <PersonOutlineOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                  <TextField
                    className="form_input_field"
                    label="Secret ID"
                    variant="outlined"
                    fullWidth
                    type="password"
                    value={password}
                    onChange={(pass) => {
                      setPassword(pass.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <LockOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                  <Button
                    type="submit"
                    onClick={handleFormLogin}
                    className={
                      !username || !password
                        ? "login_btn_disabled"
                        : "login_btn_valid"
                    }
                  >
                    Se connecter
                  </Button>
                </FormControl>

                <Link href="#" underline="none" color="#5c4ea0">
                  {"Mot de passe oublié ?"}
                </Link>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </Grid>
      <Grid
        item
        md={1}
        display={{ xs: "none", md: "block" }}
        style={{ backgroundColor: "#EFECEC " }}
      >
       
      </Grid>
    </Grid>
  );
}
// const LoginView= () =>
// {
//   let history = useNavigate();
//   const [username, setUsername] = useState();
//   const [password, setPassword] = useState();
//   const [error, setError] = useState();
//   return <div>hamza</div>
// }


export default LoginView;
