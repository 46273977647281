import React, { useEffect, useState } from "react";
import {useSelector, useDispatch } from "react-redux";
import { Button, Card, CardContent, FormControl,
    Grid, InputLabel, MenuItem, Select, Table, TableBody,
     TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography,
     Paper,TableContainer,Divider,Container
   } from "@mui/material";


//css 
import "./caisse.css";

//Icons  
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import CameraAltIcon from '@mui/icons-material/CameraAlt';


//My Components 
import MyTitle from "./components/MyTitle"
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/authactions";
import { getEndPoint, saveLogoutInLocalStorage } from "../../helpers/constants";
import MyTextArea from "./components/MyTextArea";

//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";
import axios from "axios";

const View= () => {
  //Retrait Banque Part
  const [retraitBanqueMontant,setRetraitBanqueMontant]=useState(null);
  const formatMoney= (val)=> {
    var parts = (val.toFixed(2)).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".")+" DH";
  
}
  const bankWithdrawalPossibilities=()=>{
    if(cashRegisterTotal==null || cashRegisterTotal=="" || isNaN(cashRegisterTotal) || !(searchLocal!="" && searchLocal!="BANK") )
    {
      return [];
    }
    var possibilities=[];
    var m=500;
    while(m<cashRegisterTotal)
    {
      possibilities.push(m);
      m+=500;
    }
    return possibilities;

  }

  const [bankWithdrawalClicked,setBankWithdrawalClicked]=useState(false);
  const executeBankWithdrawal= async()=>
    {
    if(!bankWithdrawalClicked && retraitBanqueMontant!=null && !isNaN(retraitBanqueMontant) && (searchLocal!="" && searchLocal!="BANK"))
    {
      setBankWithdrawalClicked(old =>true);
    
      let formData=new FormData();
      formData.append("localId",searchLocal);
      formData.append("amount",retraitBanqueMontant.toFixed(2));

 
    const endpoint=getEndPoint("/cashRegisterToBankTransfer");
    const response = await axios.post(endpoint,formData,headers).catch(function (error) {
      if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
        console.log("You're not allowed buddy");
        freeTokenFromSessionStorage();
       
      }
 
    });
    if(response!=null)
    {
      loadCashRegisters();
      setRetraitBanqueMontant(old =>null);
      await loadTransactions();
    }
    }
    setBankWithdrawalClicked(old =>false);

  }

  


  //Agent Identification
  const decodedJWT=useSelector(state => state.decodedJWT );
  useEffect(()=>{
    console.log("Decoded JWT :");
    console.log(decodedJWT);
    if(decodedJWT.Role!="SuperAdmin")
    setSearchLocal(old => decodedJWT.localId);
 
   },[decodedJWT]);


    //Locals
    const [locals,setLocals]=useState([]);


   //Cash Register Transactions 
    const [cashRegisters,setCashRegisters]=useState([]);
    const [depositLocalId,setDepositLocalId]=useState("");
    const [depositAmount,setDepositAmount]=useState("");
    const [depositDescription,setDepositDescription]=useState("");
    const [WithdrawalLocalId,setWithdrawalLocalId]=useState("");
    const [withdrawalAmount,setWithdrawalAmount]=useState("");
    const [withdrawalDescription,setWithdrawalDescription]=useState("");

    //Search Part
    const [search,setSearch]=useState("");
    const [searchLocal,setSearchLocal]=useState("");
    const [searchType,setSearchType]=useState("");
    const [searchTransactionType,setSearchTransactionType]=useState("");
    const [cashRegisterTotal,setCashRegisterTotal]=useState("");
    const cashRegisterOfLocalId= (localid) =>
    {
        setSearchLocal((old)=> localid);   
        const register=cashRegisters.find(element => element.localId==localid); 
        if(register==null)
        setCashRegisterTotal((old)=>"");
        else
        setCashRegisterTotal((old) => (register.total))
    }
    
    //Table
    const [transactions,setTransactions]=useState([]);
    const tableSize=10;
    const [page, setPage] = useState(0);    
    const [transactionsCount,setTransactionsCount]=useState(0);
    const handleChangePage = (event,newPage) => 
    {
        setPage(newPage);
    };

    //Transactions Methods 
    const depositMethod= async ()=>{
        if(!isNaN(depositAmount) && decodedJWT.Role=="SuperAdmin")
        {
           const data={
                amount:depositAmount,
                type:"manual",
                transaction_type:"DEPOSIT",
                agentId:null,
                localId:depositLocalId,
                description:depositDescription
            }
            const endpoint=getEndPoint("/Transaction");
            const response = await axios.post(endpoint,data,headers).catch(function (error) {
              if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
                console.log("You're not allowed buddy");
                freeTokenFromSessionStorage();
               
              }
         
            });
            if(response!=null)
            {
              loadCashRegisters();
              emptyDepositInputs();
              await loadTransactions();
            }

        }
        
     

    }
    const withdrawalMethod= async ()=>{
        if(!isNaN(depositAmount) && decodedJWT.Role=="SuperAdmin")
        {
            const data={
                amount:withdrawalAmount,
                type:"manual",
                transaction_type:"WITHDRAWAL",
                agentId:null,
                localId:WithdrawalLocalId,
                description:withdrawalDescription
            }
            const endpoint=getEndPoint("/Transaction");
            const response = await axios.post(endpoint,data,headers).catch(function (error) {
              if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
                console.log("You're not allowed buddy");
                freeTokenFromSessionStorage();
               
              }
         
            });
            if(response!=null)
            {
              loadCashRegisters();
                console.log("withdrawal");
                console.log(response.data);
                emptyWithDrawalInputs();
              await loadTransactions();
            }

        }
        
     

    }
    const emptyDepositInputs=()=> {
        setDepositLocalId("");
        setDepositAmount("");
        setDepositDescription("");

    }
    const emptyWithDrawalInputs=()=> {
        setWithdrawalLocalId("");
        setWithdrawalAmount("");
        setWithdrawalDescription("");

    }




    // JWT and  freesession
    const history=useNavigate();
    const jwt=useSelector(state => state.jwt);
    const dispatch=useDispatch();
    const headers = { headers: {
        "Authorization" : `Bearer ${jwt}`,
      
  
      } }
    const freeTokenFromSessionStorage = () => {
        dispatch(logout);
        saveLogoutInLocalStorage();
        history("/login");
      };
   

    //Loads 
     const loadTransactions= async() => {
      const endpoint=getEndPoint("/Transaction");
      const endPointWithPaginationProperties=endpoint+"?size="+tableSize+"&page="+page+"&typeFilter="+searchType+"&localIdFilter="+searchLocal+"&transactionTypeFilter="+searchTransactionType+"&search="+search;
        const data= await axios.get(endPointWithPaginationProperties,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
           
          }
      
        });
        console.log("return data ");
        console.log(data.data);
        setTransactions(old => data.data.content);
        setTransactionsCount(old => data.data.totalElements);
     }
     const loadCashRegisters= async ()=> {
        const endpoint=getEndPoint("/CashRegister");
        const data= await axios.get(endpoint,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
           
          }
      
        });
        setCashRegisters(old => data.data);
        const localid=await loadLocals();
        const cashRegister=((data.data).find(element => element.localId==localid));
        console.log("My CashRegister");
        console.log(cashRegister);
        setCashRegisterTotal((old) => (cashRegister.total));
     }
     const loadLocals= async() => {
        const endpoint=getEndPoint("/Local");
        const data= await axios.get(endpoint,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
           
          }
      
        });
        setLocals(old => data.data);
        setSearchLocal((old) => data.data[0]?.id);
        return (data.data[0]?.id);
      
      }

    
      useEffect(()=>{
        loadTransactions();
    },[page,searchLocal,searchTransactionType,searchType]);

     useEffect(()=>{
        //loadLocals already inside loadCashRegisters
        loadCashRegisters();
    
       
     },[]);

    return ( <div>
             <Card className="searchCard" variant="outlined">

<CardContent>

<p  className="cornerText"> Retrait Banque  </p>
<Grid container direction="row" justifyContent="space-around" alignItems="baseline">
 
     {
      decodedJWT.Role=="SuperAdmin" &&
      <Grid item>
      <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
      Local :
    </Typography>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">ID Local</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="LocalID"
          className="MySelectStyle"
          value={searchLocal}
          onChange={(event)=> {cashRegisterOfLocalId(event.target.value)}}
        >
         
          {locals.map(local =>(
            <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
          )
            )}

   
        </Select>
      </FormControl>
      </Grid>
     }
      
              <Grid item>
      <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
      Montant :
    </Typography>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Montant</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="LocalID"
          className="MySelectStyle"
          value={retraitBanqueMontant}
          onChange={(event)=> {setRetraitBanqueMontant(event.target.value)}}
        >
      
          {bankWithdrawalPossibilities().map(montant =>(
            <MenuItem key={montant} value={montant}>{formatMoney(montant)}</MenuItem>
          )
            )}
          
   
        </Select>
      </FormControl>
      </Grid>

  </Grid>
  <br/>
              
   
              <br/>
              <br/>
                <Button onClick={executeBankWithdrawal}   className={"normalButton2"} variant="contained" endIcon={<AddIcon />}>
                Valider
                  </Button> 
</CardContent>
</Card>
<br/>

      {decodedJWT.Role=="SuperAdmin" &&    <Grid container spacing={1}>

<Grid item md={6} xs={12}>
   <Card className="creationCard" variant="outlined">
       
      
   <CardContent >
             <MyTitle bgColor={"RED"}>RETRAIT D'ARGENT</MyTitle>
             <br/>
             <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
             <Grid item>

               <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
               Local :
               </Typography>
               <FormControl fullWidth>
               <InputLabel id="demo-simple-select-label">ID Local</InputLabel>
               <Select
                   labelId="demo-simple-select-label"
                   id="demo-simple-select"
                   label="LocalID"
                   className="secondSelectStyle"
                   value={WithdrawalLocalId}
                   onChange={(event)=> {setWithdrawalLocalId((old)=> (event.target.value))}}
               >
             
          
               {locals.map(local =>(
                   <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
                   )
                   )}
                    <MenuItem key={"BANK"} value={"BANK"}>BANK</MenuItem>

               </Select>
               </FormControl>
               </Grid>
               </Grid>
             <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
             <Grid item >
                 <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Montant  : 
               </Typography>
               <MyInput 
               placeholder="Montant"  value={withdrawalAmount} onChange={(event) => {setWithdrawalAmount(old => event.target.value)}}
       />
                 </Grid>
         
                 </Grid>
                 <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
             <Grid item >
                 <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Description  : 
               </Typography>
               <MyTextArea 
               placeholder="Description"  value={withdrawalDescription} onChange={(event) => {setWithdrawalDescription(old => event.target.value)}}
       />
                 </Grid>
         
                 </Grid>
           
                 <br/>
              
   
           <br/>
           <br/>
             <Button onClick={withdrawalMethod}   className={"normalButton2"} variant="contained" endIcon={<AddIcon />}>
             Valider
               </Button> 
             
           
           </CardContent>
         
       
           </Card>  
           </Grid>
           <Grid item md={6} xs={12}>
   <Card className="creationCard" variant="outlined">
       
      
   <CardContent >
             <MyTitle bgColor={"GREEN"}>DÉPÔT D'ARGENT</MyTitle>
             <br/>
             <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
             <Grid item>

               <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
               Local :
               </Typography>
               <FormControl fullWidth>
               <InputLabel id="demo-simple-select-label">ID Local</InputLabel>
               <Select
                   labelId="demo-simple-select-label"
                   id="demo-simple-select"
                   label="LocalID"
                   className="secondSelectStyle"
                   value={depositLocalId}
                   onChange={(event)=> {setDepositLocalId((old)=> (event.target.value))}}
               >

               {locals.map(local =>(
                   <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
                   )
                   )}
                   <MenuItem key={"BANK"} value={"BANK"}>BANK</MenuItem>

               </Select>
               </FormControl>
               </Grid>
               </Grid>
             <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
             <Grid item >
                 <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Montant  : 
               </Typography>
               <MyInput 
               placeholder="Montant"  value={depositAmount} onChange={(event) => {setDepositAmount(old => event.target.value)}}
       />
                 </Grid>
         
                 </Grid>
                 <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
             <Grid item >
                 <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Description  : 
               </Typography>
               <MyTextArea 
               placeholder="Description"  value={depositDescription} onChange={(event) => {setDepositDescription(old => event.target.value)}}
       />
                 </Grid>
         
                 </Grid>
        
           
                 <br/>
              
   
           <br/>
           <br/>
             <Button  onClick={depositMethod}  className={"normalButton2"} variant="contained" endIcon={<AddIcon />}>
                 Valider
               </Button> 
             
           
           </CardContent>
         
       
           </Card>  
           </Grid>
  
           </Grid>}

            <br/>
            <Card className="searchCard" variant="outlined">

        <CardContent>

        <p  className="cornerText">Montant Caisse : {cashRegisterTotal!="" ? cashRegisterTotal+" DH"  : ""} </p>
        <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          <Grid item>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Description :
            </Typography>
            <MyInput value={search} onChange={(event) =>{setSearch((old)=> (event.target.value))}} 
            placeholder="Description" 
      /> <Button onClick={loadTransactions} variant="contained" style={{'backgroundColor':"#212121"}}className="filtreSearchButton"><SearchIcon style={{"fontSize":"28px"}}/></Button>
              </Grid>
              <Grid item>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Local :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Local</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="LocalID"
                  className="MySelectStyle"
                  value={searchLocal}
                  onChange={(event)=> {cashRegisterOfLocalId(event.target.value)}}
                >
                  {decodedJWT.Role=="SuperAdmin" && <MenuItem value="">Tous</MenuItem>}
             
                  {
                  decodedJWT.Role=="SuperAdmin" ? 
                  locals.map(local =>(
                    <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
                    )
                    )
                    :
                    <MenuItem key={decodedJWT.localId} value={decodedJWT.localId}>{decodedJWT.localId}</MenuItem>
          

                }
             {(decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Admin") &&  <MenuItem value="BANK">Banque</MenuItem>} 
                    
           
                </Select>
              </FormControl>
              </Grid>
              <Grid item>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Type :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type"
                  className="MySelectStyle"
                  value={searchType}
                  onChange={(event)=> {setSearchType((old) => (event.target.value))}}
                >
                  <MenuItem value="">Tous</MenuItem>
                  <MenuItem value="manual">manuelle</MenuItem>
                  <MenuItem value="systematic">systématique</MenuItem>
           
                </Select>
              </FormControl>
              </Grid>
              <Grid item>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Type Transaction:
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type Transaction</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label=" Type Transaction"
                  className="MySelectStyle"
                  value={searchTransactionType}
                  onChange={(event)=> {setSearchTransactionType((old) => (event.target.value))}}
                >
                  <MenuItem value="">Tous</MenuItem>
                  <MenuItem value="WITHDRAWAL">Retrait</MenuItem>
                  <MenuItem value="DEPOSIT">Dépôt</MenuItem>
           
                </Select>
              </FormControl>
              </Grid>
          </Grid>
        </CardContent>
    </Card>
    <br/>
    <TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">Date</TableCell>
      <TableCell className="filtertitle" align="left">Montant</TableCell>
      <TableCell className="filtertitle" align="left">Type</TableCell>
      <TableCell className="filtertitle" align="left">Type Transaction</TableCell>
      <TableCell className="filtertitle" align="left">ID local</TableCell>
      <TableCell className="filtertitle" align="left">Description</TableCell>
     
 
    </TableRow>
  </TableHead>

  <TableBody>
  {transactions
.map(transaction => (
<TableRow
// key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{transaction.created_At}</TableCell>
<TableCell align="left">{transaction.amount} DH</TableCell>
<TableCell  align="left">{
transaction.type=="manual" ?
"manuelle"
:
"systématique"

}</TableCell>
<TableCell  align="left">
    {
    transaction.transaction_type=="DEPOSIT"?
    <Button disabled={true} className="myCompletedButton">Dépôt</Button>
:
<Button disabled={true} className="myFailedButton" >Retrait</Button>
}
    
    </TableCell>
<TableCell  align="left">{transaction.localId}</TableCell>
<TableCell  align="left" dangerouslySetInnerHTML={{__html: transaction.description}}></TableCell>
</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={transactionsCount}
          rowsPerPage={tableSize}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>
</TableContainer>
         </div>);
 
 };
 export default View;