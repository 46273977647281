
import "./foodManagement.css"

//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";
import { useEffect, useState } from "react";


//Mui 
import { Card,CardContent, Grid ,Typography,Button,TableContainer,Table
  ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
  Paper,Select,MenuItem,FormControl,InputLabel } from "@mui/material";
//Icons
import AddIcon from '@mui/icons-material/Add';
import { generateCookingFactors, getEndPoint, saveLogoutInLocalStorage , getCompressedImage } from "../../helpers/constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import EditIcon from '@mui/icons-material/Edit';

//Redux 
import { logout } from "../../redux/auth/authactions";
import { FoodImage } from "../../globalComponents/MyImageContainers";
import Online from "./components/Online";
import Offline from "./components/Offline";

//Modals 
import UpdateModal from "./components/UpdateModal"

const View= () =>{
     //Locals
     const [locals,setLocals]=useState([]);
    // Create Product Data
    const [foodId,setFoodId]=useState("");

    const [nameEn,setNameEn]=useState("");
    const [nameFr,setNameFr]=useState("");
    const [nameAr,setNameAr]=useState("");
    const [type,setType]=useState("");
    const [buyPricePer100g,setBuyPricePer100g]=useState("");
    const [sellPricePer100g,setSellPricePer100g]=useState("");
    const [proteinPer100g,setProteinPer100g]=useState("");
    const [carbsPer100g,setCarbsPer100g]=useState("");
    const [fatPer100g,setFatPer100g]=useState("");
    const [cookingFactor,setCookingFactor]=useState(1.0);
    const [foodImage,setFoodImage]=useState(null);
    const clearCreateProductInput=()=>{
      setNameEn(old => "");
      setNameFr(old => "");
      setNameAr(old => "");
      setType(old => "");
      setBuyPricePer100g(old => "");
      setSellPricePer100g(old => "");
      setProteinPer100g(old => "");
      setCarbsPer100g(old => "");
      setFatPer100g(old => "");
      setFoodImage(old => null);
    
    }

    // Filtres Part
    const [typeValue,setTypeValue]=useState("ALL");
    const [search,setSearch]= useState("");

    //Table Part 
    const [foodList,setFoodList]=useState([]);
    const [foodCount,setFoodCount]=useState(0);
    const [page, setPage] = useState(0);
    const handleChangePage = (event,newPage) => 
    {
        setPage(newPage);
    };


    //Jwt and freeSession 
    const history=useNavigate();
    const jwt=useSelector(state => state.jwt);
    const dispatch=useDispatch();
    const headers = { headers: {
        "Authorization" : `Bearer ${jwt}`,
  
      } }
    const freeTokenFromSessionStorage = () => {
        dispatch(logout);
        saveLogoutInLocalStorage();
        history("/login");
      };

    //Methods 
    const createProduct= async() => {
      let formData=new FormData();
      const data={
        id:foodId,
        name:{
          "en":nameEn,
          "fr":nameFr,
          "ar":nameAr,
        },
        type:type,
        proteinPer100g:proteinPer100g,
        carbsPer100g:carbsPer100g,
        fatPer100g:fatPer100g,
        buyPricePer100g:buyPricePer100g,
        sellPricePer100g:sellPricePer100g,
        cookingFactor:cookingFactor,
        activated:false,
      }
      formData.append("data",JSON.stringify(data));

      var compressedImage=await getCompressedImage(foodImage);
      formData.append("image",compressedImage);

      const endpoint=getEndPoint("/create");
      const response = await axios.post(endpoint,formData,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
     
          freeTokenFromSessionStorage();
         
        }
   
      });
      if(response!=null)
      {
        console.log("my sent Data:");
        console.log(data);
        console.log("returned Data");
        console.log(response.data);
      clearCreateProductInput();
      loadFoods();
      }
    }

    //Upadat Modal 
      const [selectedFood,setSelectedFood]=useState({});
      const [modalIsOpen,setModalIsOpen]=useState(false);
    const openUpdateModal=(food) =>{
      setSelectedFood(food);
      setModalIsOpen(true);
    }
    const closeUpdateModal=()=>{
      setModalIsOpen(olde => false);
      
    }
    const handleUpdate= async ({data})=>{
      let formData=new FormData();
      const myData={
        id:data.id,
        name:data.name,
        type:data.type,
        proteinPer100g:data.proteinPer100g,
        carbsPer100g:data.carbsPer100g,
        fatPer100g:data.fatPer100g,
        buyPricePer100g:data.buyPricePer100g,
        sellPricePer100g:data.sellPricePer100g,
        cookingFactor:data.cookingFactor,
        image:data.image,
        activated:data.activated,
      }
      formData.append("data",JSON.stringify(myData));

      var compressedImage=await getCompressedImage(data.foodImage);
      formData.append("image",compressedImage);

      const endpoint=getEndPoint("/update");
      const response = await axios.put(endpoint,formData,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
        console.log("Update Error Response Status :");
        console.log(error.response);
   
      });
      if(response!=null && response.data!=null)
      {
        loadFoods();
      }

      closeUpdateModal();
      
        
    }
    //Loads 

    const loadFoods=async()=>{
      const endpoint=getEndPoint("/foodListPageable?page="+page+"&type="+typeValue);
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
   
      });
      

      if(data!=null)
      {
          setFoodList((old) => data.data.content);
          setFoodCount((old) => data.data.totalElements);

      }

  };

  const formatKgfromGram=(value) =>
  {
    return (value/1000).toFixed(2)+"kg";

  }
  const loadLocals= async() => {
    const endpoint=getEndPoint("/Local");
    const data= await axios.get(endpoint,headers).catch(function (error) {
      if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
        console.log("You're not allowed buddy");
        freeTokenFromSessionStorage();
       
      }
 
    });
    setLocals(old => data.data);

  }
  useEffect(()=> {
    loadLocals();

  },[]);

  useEffect(()=>{
    loadFoods();
  },[page,typeValue]);
    return (<div >
        <UpdateModal  open={modalIsOpen} food={selectedFood} handleClose={closeUpdateModal} handleUpdate={handleUpdate} />

     <Card className="creationCard" variant="outlined">
<CardContent>
          <p  className="cornerText">Nouveau Produit </p>
         
          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
           ID :
            </Typography>
            <MyInput value={foodId} onChange={(event) => setFoodId(event.target.value)} 
            placeholder="ID" 
          />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
           Food Name :
            </Typography>
        
              <Grid container direction="column">
                <Grid item>
                <MyInput value={nameEn} onChange={(event) => setNameEn(event.target.value)} 
            placeholder="English" 
          />
          
          
                </Grid>
                <Grid item>
                <MyInput value={nameFr} onChange={(event) => setNameFr(event.target.value)} 
            placeholder="Francais" 
          />
                </Grid>
                <Grid item>
                <MyInput value={nameAr} onChange={(event) => setNameAr(event.target.value)} 
            placeholder="العربية" 
          />
                </Grid>
              </Grid>
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Food Type :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">TypeProduit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Produit"
                  value={type}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setType(event.target.value)}} 
                >             
                   <MenuItem  value={"Fat"}>Fat</MenuItem>
                   <MenuItem  value={"Protein"}>Protein</MenuItem>
                   <MenuItem  value={"Carbs"}>Carbs</MenuItem>
                   <MenuItem  value={"Protein_Others_Gram"}>Protein_Others_Gram</MenuItem>
                   <MenuItem  value={"Fat_Others_Gram"}>Fat_Others_Gram</MenuItem>
                   <MenuItem  value={"Fruits_Legumes"}>Fruits_Legumes</MenuItem>
                   <MenuItem  value={"Sauces"}>Sauces</MenuItem>
                   <MenuItem  value={"Fromages"}>Fromages</MenuItem>
                   <MenuItem  value={"Embalage"}>Embalage</MenuItem>
                   <MenuItem  value={"Pain"}>Pain</MenuItem>
                   <MenuItem  value={"Autres"}>Autres</MenuItem>
                   
                </Select>
              </FormControl>
          
              </Grid>
            
              </Grid>
              <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Protein Per 100g :
            </Typography>
            <MyInput value={proteinPer100g} onChange={(event) =>{setProteinPer100g(event.target.value)}} 
            placeholder="Protein Per 100g" 
         />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Carbs Per 100g :
            </Typography>
            <MyInput value={carbsPer100g} onChange={(event) =>{setCarbsPer100g(event.target.value)}} 
            placeholder="Carbs Per 100g " 
      />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Fat Per 100g :
            </Typography>
            <MyInput value={fatPer100g} onChange={(event) =>{setFatPer100g(event.target.value)}} 
            placeholder="Fat per 100g" 
          />
              </Grid>
             </Grid>
             <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
            
             <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Cooking Factor :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">TypeProduit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Cooking Factor"
                  value={cookingFactor}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setCookingFactor(event.target.value)}} 
                >       
                {generateCookingFactors().map((value) => (
                  <MenuItem  value={value}>{value}</MenuItem>
                ))}      
                 
                   
                </Select>
              </FormControl>
          
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              BuyPrice Per 100g:
            </Typography>
            <MyInput value={buyPricePer100g} onChange={(event) =>{setBuyPricePer100g(event.target.value)}} 
            placeholder="BuyPrice Per 100g" 
           />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              SellPrice Per 100g:
            </Typography>
            <MyInput value={sellPricePer100g} onChange={(event) =>{setSellPricePer100g(event.target.value)}} 
            placeholder="SellPrice Per 100g" 
           />
              </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
           Food Image :
            </Typography>
            <Button
                 style={{
                  "background-color": "#212121",
                  "color":"#FFFFFF"
          
               }}
      
                       variant="contained"
                        component="label" 
                 
                
                         className={foodImage!==null? "insertPhotoButton ":""}
                      
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                         onChange={(event) => setFoodImage(old => event.target.files[0])}
                        />
                      </Button>
              </Grid>
              </Grid>
       
           <br/>
           <br/>
              <Button      style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }}
     onClick={createProduct} className={"normalButton2"} variant="contained" endIcon={<AddIcon />}>
              Créer
            </Button>

    
          
          
        </CardContent>
        </Card>
        <br/>
        <Card className="searchCard" variant="outlined">

<CardContent>

<p  className="cornerText">Filtres </p>
<Grid container direction="row" justifyContent="space-around" alignItems="baseline">

   
      <Grid item>
      <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
      Type Produit :
    </Typography>
    <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">TypeProduit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Produit"
                  value={typeValue}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{
                    setPage(0);
                    setTypeValue(event.target.value);
                  }} 
                >  
                   <MenuItem  value={"ALL"}>ALL</MenuItem>           
                   <MenuItem  value={"Fat"}>Fat</MenuItem>
                   <MenuItem  value={"Protein"}>Protein</MenuItem>
                   <MenuItem  value={"Carbs"}>Carbs</MenuItem>
                   <MenuItem  value={"Protein_Others_Gram"}>Protein_Others_Gram</MenuItem>
                   <MenuItem  value={"Fat_Others_Gram"}>Fat_Others_Gram</MenuItem>
                   <MenuItem  value={"Fruits_Legumes"}>Fruits_Legumes</MenuItem>
                   <MenuItem  value={"Sauces"}>Sauces</MenuItem>
                   <MenuItem  value={"Fromages"}>Fromages</MenuItem>
                   <MenuItem  value={"Embalage"}>Embalage</MenuItem>
                   <MenuItem  value={"Pain"}>Pain</MenuItem>
                   <MenuItem  value={"Autres"}>Autres</MenuItem>
             
        
                </Select>
              </FormControl>
      </Grid>
    
  </Grid>
</CardContent>
</Card>
<br/>
<TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">ID</TableCell>
      <TableCell className="filtertitle" align="left">Name</TableCell>
      <TableCell className="filtertitle" align="left">Type</TableCell>
      <TableCell className="filtertitle" align="left">ProteinPer100g</TableCell>
      <TableCell className="filtertitle" align="left">CarbsPer100g</TableCell>
      <TableCell className="filtertitle" align="left">FatPer100g</TableCell>
      <TableCell className="filtertitle" align="left">Quantity</TableCell>
      <TableCell className="filtertitle" align="left">CookingFactor</TableCell>
      <TableCell className="filtertitle" align="left">buyPricePer100g</TableCell>
      <TableCell className="filtertitle" align="left">sellPricePer100g</TableCell>
      <TableCell className="filtertitle" align="left">Image</TableCell>
      <TableCell className="filtertitle" align="left">isActivated</TableCell>
      <TableCell className="filtertitle" align="left">Edit</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {foodList
.map(food => (
<TableRow
 key={food.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell align="left">{food.id}</TableCell>
<TableCell align="left">{food.name.en}<br/>{food.name.fr}<br/>{food.name.ar}</TableCell>
<TableCell  align="left">{food.type}</TableCell>
<TableCell  align="left">{food.proteinPer100g} g</TableCell>
<TableCell  align="left">{food.carbsPer100g} g</TableCell>
<TableCell  align="left">{food.fatPer100g} g</TableCell>
<TableCell  align="left">
{locals.map(local =>(
                   <div>{local.id} : {food.quantity==null ? 0 : food.quantity[local.id] ?  formatKgfromGram(food.quantity[local.id]) : 0} <br/></div>
                  )
                    )}
  {/* local 1 :{product.quantity?.l1}<br/>local 2 :{product.quantity?.l2}<br/>local 3 :{product.quantity?.l3} */}
  
  </TableCell>
<TableCell  align="left">{food.cookingFactor}</TableCell>
<TableCell  align="left">{food.buyPricePer100g} DH</TableCell>
<TableCell  align="left">{food.sellPricePer100g} DH</TableCell>
<TableCell  align="left"><FoodImage imageName={food.image}/></TableCell>

<TableCell align="left">
      {food.activated ? <Online/> : <Offline/>}

        </TableCell>
<TableCell align="left">
  <EditIcon 
  onClick={() => {openUpdateModal(food)}}
    className="editIcon2"/>
    </TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={foodCount}
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
    </div>)
}
export default View;