//Mui 
import { Card,CardContent, Grid ,Typography,Button,TableContainer,Table
    ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
    Paper,Select,MenuItem,FormControl,InputLabel } from "@mui/material";
import { useEffect, useState } from "react";

//Redux/JWT
import { logout } from "../../redux/auth/authactions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Css
import "./orders.css";

// Api Calls
import axios from "axios";
import { generateCookingFactors, getEndPoint, saveLogoutInLocalStorage } from "../../helpers/constants";

//Icons
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//Modals
import ExploreModal from "./ExploreModal";
import RejectConfModal from "./RejectConfModal";





const View= () =>{



    const [orderStatusFilter,setOrderStatusFilter]=useState("cooking")
    const [orders,setOrders]=useState([]);
    const [ordersCount,setOrdersCount]=useState(0);
    const [page, setPage] = useState(0);

    const handleChangePage = (event,newPage) => 
    {
        setPage(newPage);
    };


    const [deliveringOrders,setDeliveringOrders]=useState([]);
    const [deliveringOrdersCount,setDeliveringOrdersCount]=useState(0);
    const [deliveringPage, setDeliveringPage] = useState(0);

    const handleChangeDeliveringPage = (event,newPage) => 
    {
        setDeliveringPage(newPage);
    };



       //Jwt and freeSession 
       const history=useNavigate();
       const jwt=useSelector(state => state.jwt);
       const dispatch=useDispatch();
       const headers = { headers: {
           "Authorization" : `Bearer ${jwt}`,
         } }
       const freeTokenFromSessionStorage = () => {
           dispatch(logout);
           saveLogoutInLocalStorage();
           history("/login");
         };

         //Delete
         const [selectedRejectOrder,setSelectedRejectOrder]=useState({});
         const [rejectModalIsOpen,setRejectModalIsOpen]=useState(false);
       const openRejectModal=(order) =>{
        setSelectedRejectOrder(order);
        setRejectModalIsOpen(true);
       }
       const closeRejectModal=()=>{
        setRejectModalIsOpen(olde => false);
         
       }

         //Explore
         const [selectedOrder,setSelectedOrder]=useState({});
         const [modalIsOpen,setModalIsOpen]=useState(false);
       const openExploreModal=(order) =>{
        setSelectedOrder(order);
        setModalIsOpen(true);
       }
       const closeExploreModal=()=>{
        setModalIsOpen(olde => false);
         
       }


       //complete Order
       const completeOrder= async(myOrder) =>
       {
       const endpoint=getEndPoint("/order/complete?id="+myOrder.id);
       const data= await axios.put(endpoint,{},headers).catch(function (error) {
         if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
           console.log("You're not allowed buddy");
           freeTokenFromSessionStorage();
          
         }
    
       });
       if(data.status=="200")
       {
           loadOrders();
           loadDeliveringOrders();
       }


      }
       //cook order
        const cookOrder= async(myOrder) =>
        {
        const endpoint=getEndPoint("/order/cook?id="+myOrder.id);
        const data= await axios.put(endpoint,{},headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
           
          }
     
        });
        if(data.status=="200")
        {
            loadOrders();
            loadDeliveringOrders();
        }


       }
       const rejectOrder= async(myOrder) =>
       {
   

       const endpoint=getEndPoint("/order/reject?id="+myOrder.id);
       const data= await axios.put(endpoint,{},headers).catch(function (error) {
         if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
           console.log("You're not allowed buddy");
           freeTokenFromSessionStorage();
          
         }
    
       });
       if(data.status=="200")
       {
           loadOrders();
           loadDeliveringOrders();
       }


      }

             //Loads 

    const formatTime=(time) =>
    {
        if(time==null || time=="")
        {
            return "";
        }
        console.log("Time");
        console.log(time);
        var result="";
       var mySplit=time.split("T");
       result+=mySplit[0]+" ";
       var mySecondSplit=mySplit[1].split(":");
       mySecondSplit[2]=Number.parseFloat(mySecondSplit[2]).toFixed(0);
       if(mySecondSplit[0].length==1)
       {
        mySecondSplit[0]="0"+mySecondSplit[0];

       }
       if(mySecondSplit[1].length==1)
       {
        mySecondSplit[1]="0"+mySecondSplit[1];

       }
       if(mySecondSplit[2].length==1)
       {
        mySecondSplit[2]="0"+mySecondSplit[2];

       }
       result+=mySecondSplit[0]+":"+mySecondSplit[1]+":"+mySecondSplit[2];
       return result;
        
    }

    const loadOrders=async()=>{
        console.log("Load Called");
        console.log("Type "+orderStatusFilter);

        const endpoint=getEndPoint("/order/listPageable?page="+page+"&type="+orderStatusFilter);
        const data= await axios.get(endpoint,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
           
          }
     
        });

        console.log("Response");
        console.log(data);
        console.log("Response Data");
        console.log(data.data);
        
  
        if(data!=null)
        {
            setOrders((old) => data.data.content);
            setOrdersCount((old) => data.data.totalElements);
           console.log(orders);
  
        }
  
    };
    const loadDeliveringOrders=async()=>{
        console.log("Delivering Orders Called");
        const endpoint=getEndPoint("/order/listPageable?page="+deliveringPage+"&type=deliveringdelivered");
        const data= await axios.get(endpoint,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
           
          }
     
        });
        
  
        if(data!=null)
        {
            setDeliveringOrders((old) => data.data.content);
            setDeliveringOrdersCount((old) => data.data.totalElements);
           console.log(orders);
  
        }
  
    };

    const decodedJWT=useSelector(state => state.decodedJWT );
    // useEffect(()=>{

    //   // if(decodedJWT.Role!="SuperAdmin")
    //   // setSearchLocal(old => decodedJWT.localId);
   
    //  },[decodedJWT]);

    useEffect(

      () =>{
        loadOrders();
      },[orderStatusFilter,page]
    )
    useEffect(
        ()=>
        {
            const interval = setInterval(() => {
                loadOrders();
              }, 10000);
              return () => clearInterval(interval);

     

        },[orderStatusFilter]
    );
    useEffect(
      ()=> 
      {
        loadDeliveringOrders();
      },[deliveringPage]
    )
    useEffect(
        () => 
        {
          
            const interval = setInterval(() => {
                loadDeliveringOrders();
              }, 10000);
              return () => clearInterval(interval);

        },[]
    )

    return (<div>
           <RejectConfModal  open={rejectModalIsOpen} order={selectedRejectOrder} handleClose={closeRejectModal} handleReject={() => {
            rejectOrder(selectedRejectOrder);
            closeRejectModal();
           }} />
           <ExploreModal  open={modalIsOpen} order={selectedOrder} handleClose={closeExploreModal} />

        <p>Orders</p>
        <br/>
        <Card className="searchCard" variant="outlined">

<CardContent>

<p  className="cornerText">Filtres </p>
<Grid container direction="row" justifyContent="space-around" alignItems="baseline">

   
      <Grid item>
      <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
      Order Status :
    </Typography>
    <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Produit"
                //   value={typeValue}
                  className="selectStyleNewProduct"
                  value={orderStatusFilter}
                  onChange={(event) =>{
                    setOrderStatusFilter(old => event.target.value);
                   
                  }} 
                >  
                   <MenuItem  value={"cooking"}>cooking</MenuItem>       
                   <MenuItem  value={"delivering"}>delivering</MenuItem>
                   <MenuItem  value={"delivered"}>delivered</MenuItem>
                   <MenuItem  value={"completed"}>completed</MenuItem>
                   {
     (decodedJWT.Role=="SuperAdmin") && 
     <MenuItem  value={"rejected"}>rejected</MenuItem>
      }
                 
                   <MenuItem  value={"all"}>all</MenuItem>
        
                </Select>
              </FormControl>
      </Grid>
    
  </Grid>
</CardContent>
</Card>
<br/>
<TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">Date</TableCell>
      <TableCell className="filtertitle" align="left">Phone</TableCell>
      <TableCell className="filtertitle" align="left">Macros</TableCell>
      <TableCell className="filtertitle" align="left">Calories</TableCell>
      <TableCell className="filtertitle" align="left">DeliveryPrice</TableCell>
      <TableCell className="filtertitle" align="left">TotalPrice</TableCell>
      <TableCell className="filtertitle" align="left">Explore</TableCell>
      <TableCell className="filtertitle" align="left">Action</TableCell>
      {
     (decodedJWT.Role=="SuperAdmin") && 
      <TableCell className="filtertitle" align="left">Delete</TableCell>
      }
      
     
    </TableRow>
  </TableHead>

  <TableBody>
  {orders
.map(order => (
<TableRow
 key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell align="left">{formatTime(order.orderDateTime)}</TableCell>
<TableCell  align="left">{order.deliveryData.phone}</TableCell>
<TableCell align="left">Prot:{order.totalProtein}g,<br/>Carbs:{order.totalCarbs},<br/>Fat:{order.totalFat}</TableCell>
<TableCell  align="left">{order.totalCarbs} Kcal</TableCell>
<TableCell  align="left">{order.deliveryData.deliveryPrice} MAD</TableCell>
<TableCell  align="left">{order.totalPrice} MAD {!order.payWithCash ? <CheckCircleIcon color="success"/> : ""}</TableCell>

<TableCell align="left">
  <Button 
     style={{
        "background-color": "#212121",
        "color":"#FFFFFF",
        "fontWeight":900,

     }}
     onClick={()=> {openExploreModal(order)}}
     color="info" 
    className="exploreButton">Explore</Button>
    </TableCell>
    <TableCell align="left">
        {order.orderStatus=="cooking" &&  <Button 
     style={{
        "background-color": "#52b788",
        "color":"#FFFFFF",
        "fontWeight":900,

     }}
     onClick={()=> {cookOrder(order)}}
     color="info" 
    className="exploreButton">Done</Button>}
 
    </TableCell>
    {
     (decodedJWT.Role=="SuperAdmin") &&
      <TableCell align="left">
        {
          order.orderStatus!="rejected"? 
          <Button 
     style={{
        "background-color": "#e63946",
        "color":"#FFFFFF",
        "fontWeight":900,

     }}
     onClick={()=> {

      openRejectModal(order);

     }}
     color="info" 
    className="exploreButton">Reject</Button>
    :
      <Typography style={{"color":"#e63946"}}> Rejected</Typography>
        }
  
    </TableCell>
      }

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          count={ordersCount}
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
<br/>

<br/>
<TableContainer className="deliveringtableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">Date</TableCell>
      <TableCell className="filtertitle" align="left">Phone</TableCell>
      <TableCell className="filtertitle" align="left">Macros</TableCell>
      <TableCell className="filtertitle" align="left">Calories</TableCell>
      <TableCell className="filtertitle" align="left">DeliveryPrice</TableCell>
      <TableCell className="filtertitle" align="left">TotalPrice</TableCell>
      <TableCell className="filtertitle" align="left">Explore</TableCell>
      <TableCell className="filtertitle" align="left">Status</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {deliveringOrders
.map(order => (
<TableRow
 key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell align="left">{formatTime(order.orderDateTime)}</TableCell>
<TableCell  align="left">{order.deliveryData.phone}</TableCell>
<TableCell align="left">Prot:{order.totalProtein}g,<br/>Carbs:{order.totalCarbs},<br/>Fat:{order.totalFat}</TableCell>
<TableCell  align="left">{order.totalCarbs} Kcal</TableCell>
<TableCell  align="left">{order.deliveryData.deliveryPrice} MAD</TableCell>
<TableCell  align="left">{order.totalPrice} MAD</TableCell>

<TableCell align="left">
  <Button 
     style={{
        "background-color": "#212121",
        "color":"#FFFFFF",
        "fontWeight":900,

     }}
     onClick={()=> {openExploreModal(order)}}
     color="info" 
    className="exploreButton">Explore</Button>
    </TableCell>
    {
      order.orderStatus=="delivering" ?
      <TableCell   align="left">{order.orderStatus}</TableCell>
      :
      order.orderStatus=="delivered" ?
      <TableCell   align="left">  <Button 
      style={{
         "background-color": "#2b9348",
         "color":"#FFFFFF",
         "fontWeight":900,
 
      }}
      onClick={()=> {completeOrder(order)}}
      color="info" 
     className="exploreButton">Complete</Button></TableCell>
      :
      <TableCell   align="left">Status Error</TableCell>
    }
  
</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          count={deliveringOrdersCount}
          rowsPerPage={10}
          page={deliveringPage}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangeDeliveringPage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
        </div>)
}
export default View;