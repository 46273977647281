import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Slide,
  Grid ,Typography,Select,MenuItem,FormControl,InputLabel, Table, TableContainer,
   TableRow, TableCell, TableBody, TableFooter, TablePagination,Paper,TableHead, Card, CardContent} from "@mui/material";

import React, { useState,useEffect } from "react";
import { FoodImage } from "../../../../globalComponents/MyImageContainers";
import MyInput from "../../../../globalComponents/MyInput";

import "./productSearchModal.css";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const ProductSearchModal=({index,open,handleClose,products,handleSubmit,locals}) => {

  const [page, setPage] = useState(0);
  const [search,setSearch]= useState("");
  const [stock,setStock]=useState([]);
  const [localsHook,setLocalsHook]=useState([]);
  const handleChangePage = (event,newPage) => 
   {
       setPage(newPage);
   };
   useEffect(()=>{
     console.log("products list:");
     console.log(products);
     setStock(old => products);
     setLocalsHook(locals);
   },[products]);

    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle className="cornerText"> Recherche Produit  </DialogTitle>
        <DialogContent id="dialogContent" >
        <Card className="searchCard" variant="outlined">
          

        <CardContent>
    
        <p  className="cornerText">Recherche Global  </p>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Recherche :
            </Typography>
            <MyInput value={search} onChange={(event) =>{setSearch((old)=> (event.target.value))}} 
            placeholder="Recherche" 
      />
              </Grid>
          </Grid>
      
        </CardContent>
        </Card>
        <br/> 
<TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">ID</TableCell>
      <TableCell className="filtertitle" align="left">Name</TableCell>
      <TableCell className="filtertitle" align="left">Type</TableCell>
      <TableCell className="filtertitle" align="left">Quantity</TableCell>
      <TableCell className="filtertitle" align="left">buyPricePer100g</TableCell>
      <TableCell className="filtertitle" align="left">sellPricePer100g</TableCell>
      <TableCell className="filtertitle" align="left">Image</TableCell>

    </TableRow>
  </TableHead>

  <TableBody>
  {stock
  .filter(
      
      product => (
        (product.id).toLowerCase()?.includes((search).toLowerCase())  || 
        (product.name.en).toLowerCase()?.includes((search).toLowerCase())  || 
        (product.name.fr).toLowerCase()?.includes((search).toLowerCase())  || 
        (product.name.ar).toLowerCase()?.includes((search).toLowerCase())  || 
        (product.type).toLowerCase()?.includes((search).toLowerCase()) 
       

  ))
.slice(page * 10, page * 10 + 10)
.map(product => (
<TableRow
 key={product.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
onClick={
  () => {
   handleSubmit({
     indexProduct:index,
     product: {
      found:true,
      index:index,

      mealId:product.id,
      name:product.name,
      type:product.type,

      proteinPer100g:product.proteinPer100g,
      carbsPer100g:product.carbsPer100g,
      fatPer100g:product.fatPer100g,


      buyPricePer100g:product.buyPricePer100g,
      sellPricePer100g:product.sellPricePer100g,

      quantity:"",
    
      totalProtein:null,
      totalCarbs:null,
      totalFat:null,
      
      cost:null,
      total:null,
    },
   });
   handleClose();
  }
   }
>
<TableCell component="th" scope="row" align="left">{product.id}</TableCell>
<TableCell align="left">{product.name.en},{product.name.fr},{product.name.ar}</TableCell>
<TableCell  align="left">{product.type}</TableCell>
<TableCell  align="left">
{localsHook.map(local =>(
                   <div key={local.id}>{local.id} : {product.quantity==null ? 0 :product.quantity[local.id] ? product.quantity[local.id].toFixed(3) : 0} <br/></div>
                  )
                    )}  
  </TableCell>
<TableCell  align="left">{product.buyPricePer100g} DH</TableCell>
<TableCell  align="left">{product.sellPricePer100g} DH</TableCell>
<TableCell  align="left"><FoodImage imageName={product.image}/></TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            stock.filter(
      
              product => (
                (product.id).toLowerCase()?.includes((search).toLowerCase()) || 
                (product.name.en).toLowerCase()?.includes((search).toLowerCase()) || 
                (product.name.ar).toLowerCase()?.includes((search).toLowerCase()) || 
                (product.name.fr).toLowerCase()?.includes((search).toLowerCase()) || 
                (product.type).toLowerCase()?.includes((search).toLowerCase()) 
              
        
          )).length
            }
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
        </DialogContent>
      </Dialog>
    )
};
export default ProductSearchModal;
