import React, { useEffect, useState } from "react";
import {useSelector, useDispatch } from "react-redux";
import { Button, Card, CardContent, FormControl,
    Grid, InputLabel, MenuItem, Select, Table, TableBody,
     TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography,
     Paper,TableContainer,Divider,Container
   } from "@mui/material";

   import { Link } from 'react-router-dom';


import "./news.css";

//Icons  

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import CameraAltIcon from '@mui/icons-material/CameraAlt';






//My Components 

import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/authactions";
import { getEndPoint, saveLogoutInLocalStorage ,getNewsImageUrl} from "../../helpers/constants";


//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";
import axios from "axios";

const View= () => {


   const [newsList,setNewsList]=useState([]);

    const [newsId,setNewsId]=useState("");
    const [arabicImage,setArabicImage]=useState(null);
    const [frenchImage,setFrenchImage]=useState(null);
    const [englishImage,setEnglishImage]=useState(null);


    const deleteNewsId= async(id) => {
        const endpoint=getEndPoint("/news?id="+id);
        const response = await axios.delete(endpoint,headers).catch(function (error) {
            if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
    
              freeTokenFromSessionStorage();
             
            }
       
          });
          if(response!=null && response.data!=null)
          {
            loadNews();
          }
    }
    const createNews= async()=> {
        if(newsId!="" && arabicImage!=null && frenchImage!=null && englishImage!=null)
        {
            let formData=new FormData();
            formData.append("ar",arabicImage);
            formData.append("fr",frenchImage);
            formData.append("en",englishImage);

            const endpoint=getEndPoint("/news?id="+newsId);
            const response = await axios.post(endpoint,formData,headers).catch(function (error) {
              if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
      
                freeTokenFromSessionStorage();
               
              }
         
            });
            if(response!=null && response.data!=null)
            {
                loadNews();
                emptyInput();
            }


        }

    }

    const emptyInput=() =>
    {

    setNewsId("");
    setArabicImage(null);
    setFrenchImage(null);
    setEnglishImage(null);

    }

    // JWT and  freesession
    const history=useNavigate();
    const jwt=useSelector(state => state.jwt);
    const dispatch=useDispatch();
    const headers = { headers: {
        "Authorization" : `Bearer ${jwt}`,
      
  
      } }
    const freeTokenFromSessionStorage = () => {
        dispatch(logout);
        saveLogoutInLocalStorage();
        history("/login");
      };
    

      //loads
      const loadNews=async()=>{
        const endpoint=getEndPoint("/news");
        const data= await axios.get(endpoint,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
        
            freeTokenFromSessionStorage();
           
          }
     
        });
        setNewsList(old => data.data);
  
    };
    useEffect(()=>{
        loadNews();
      },[]);


    return (<div>
        <Card className="searchCard" variant="outlined">
<CardContent>
<p  className="cornerText">News Creation </p>
<Grid container direction="row" justifyContent="space-around" alignItems="baseline">
 
<Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             News Id:
            </Typography>
            <MyInput value={newsId} onChange={(event) => setNewsId(event.target.value)} 
            placeholder="News Name" 
    />
              </Grid>
           

  </Grid>
  <br/>
  <hr/>
  <br/>
  <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
  <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
           English Image :
            </Typography>
            <Button
       
       style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }}
                        variant="contained"
                        component="label" 
                         className={englishImage!==null? "insertPhotoButton":""    }
                      
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                         onChange={(event) => setEnglishImage(old => event.target.files[0])}
                        />
                      </Button>
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
           Arabic Image :
            </Typography>
            <Button
       
       style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }}
                        variant="contained"
                        component="label" 
                         className={arabicImage!==null? "insertPhotoButton":""    }
                      
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                         onChange={(event) => setArabicImage(old => event.target.files[0])}
                        />
                      </Button>
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
           French Image :
            </Typography>
            <Button
       
       style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }}
                        variant="contained"
                        component="label" 
                         className={frenchImage!==null? "insertPhotoButton":""    }
                      
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                         onChange={(event) => setFrenchImage(old => event.target.files[0])}
                        />
                      </Button>
              </Grid>

  </Grid>

     
  <br/>
           <br/>
              <Button      style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }} onClick={createNews} className={"normalButton2"} variant="contained" endIcon={<AddIcon />}>
              Créer
            </Button>
</CardContent>
</Card>
<br/>
<TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">id </TableCell>
      <TableCell className="filtertitle" align="left">Arabic Image</TableCell>
      <TableCell className="filtertitle" align="left">French Image</TableCell>
      <TableCell className="filtertitle" align="left">English Image</TableCell>
      <TableCell className="filtertitle" align="left">Delete</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {newsList
.map(myNews => (
<TableRow
// key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{myNews.id}</TableCell>

      <TableCell component="th" scope="row" align="left"> 
      <Button href={getNewsImageUrl(myNews.ar)}   variant="contained" className="myButton"  target="_blank" rel="noreferrer"> 
      AR

  
</Button></TableCell>

<TableCell component="th" scope="row" align="left"> 
      <Button href={getNewsImageUrl(myNews.fr)}   variant="contained" className="myButton"  target="_blank" rel="noreferrer"> 
      FR

  
</Button></TableCell>

<TableCell component="th" scope="row" align="left"> 
      <Button href={getNewsImageUrl(myNews.en)}   variant="contained" className="myButton" target="_blank" rel="noreferrer"> 
      EN

  
</Button></TableCell>
<TableCell align="left">
                    <DeleteIcon  className="deleteIcon" 
                     onClick={() => deleteNewsId(myNews.id)}
                    />
                    </TableCell>



</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
        //   rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            newsList.length
            }
        //   rowsPerPage={10}
        //   page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
        //   onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
    </div>)
}
export default View;