import React, { useEffect, useState } from "react"
import { getFoodImageURL } from "../../helpers/constants";
import "./myImageContainersCss.css";

export const FoodImage=({imageName}) => {
    const [imageNameHook,setImageNameHook]=useState("");
 useEffect(()=>{
    setImageNameHook(old => imageName);
    console.log("Image: "+imageName+", Changed");
 },[imageName]);
 return <img  width={80} height={80} src={getFoodImageURL(imageNameHook)}></img>
};