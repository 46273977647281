import MyRoutes from "./routes";
import './App.css';


function App() {
  return (
    <MyRoutes />
    );
}

export default App;
