import React, { useEffect, useState } from "react";
import {useSelector, useDispatch } from "react-redux";
import { Button, Card, CardContent, FormControl,
    Grid, InputLabel, MenuItem, Select, Table, TableBody,
     TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography,
     Paper,TableContainer,Divider,Container
   } from "@mui/material";




//Icons  
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import CameraAltIcon from '@mui/icons-material/CameraAlt';




import Online from "./components/Online";
import Offline from "./components/Offline";


//My Components 

import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/authactions";
import { getEndPoint, saveLogoutInLocalStorage } from "../../helpers/constants";

import UserSearchModal from "./components/UserSearchModal";
import UpdateModal from "./components/UpdateModal"


//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";
import axios from "axios";


const View= () => {

  const createPromoCode= async()=>{
    if(ownerShare!=null && userDiscount!=null &&  id!=null && userId!=null && promoCodeEndDate!=null)
    {
 


      var data={
        "id":id,
        "userId":userId,
        "ownerShare":ownerShare,
        "userDiscount":userDiscount,
        "numberOfDaysToExpire":promoCodeEndDate,
        "enabled":promoCodeEnabled
      };
  const endpoint=getEndPoint("/promoCode");
     const response = await axios.post(endpoint,data,headers).catch(function (error) {
       if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {

         freeTokenFromSessionStorage();
        
       }
  
     });
     if(response!=null)
     {
      clearCreatePromoCode();
     // loadMeals();

     }
     
     
    

   }

   }
   const clearCreatePromoCode=() =>
   {
    setId(null);
    setUserId(null);
    setUserIdFound(null);
    setOwnerShare(0);
    setUserDiscount(0);
    setPromoCodeEndDate(null);
    setPromoCodeEnabled(true);


   }

  const getNumberOfDaysToExpire=() => {
    let values=[]
    for(let i=10;i<=365;i=i+5)
    {
     values.push(<MenuItem  value={i}>{i} Days</MenuItem>)
    }
    return values;
  }
  const getDiscountValues=() => {
    let values=[]
    for(let i=0;i<=50;i++)
    {
     values.push(<MenuItem  value={i}>{i} %</MenuItem>)
    }
    return values;
  }


  // users Hook
    const [usersList,setUsersList]=useState([]);
    //LIST OF PROMO CODES and promoCodesorders
    const [promoCodePage,setPromoCodePage]=useState(0);
    const handleChangePromoCodePage = (event,newPage) => 
    {
      setPromoCodePage(newPage);
    };
    const [promoCodeIdVariable,setPromoCodeIdVariable]=useState("");
    const [promoCodesList,setPromoCodesList]=useState([]);
    const [promoCodeOrdersList,setPromoCodeOrdersList]=useState([]);
    const [promoCodeOrdersTotalElements,setPromoCodeOrdersTotalElements]=useState(0);
    // create PromoCode 
    const [id,setId]=useState(null);
    const [userId,setUserId]=useState("");
    const [userIdFound,setUserIdFound]=useState(null);

    const [ownerShare,setOwnerShare]=useState(0);
    const [userDiscount,setUserDiscount]=useState(0);
    const [promoCodeEndDate,setPromoCodeEndDate]=useState(null);
    const [promoCodeEnabled,setPromoCodeEnabled]=useState(true);
    
    const  setUserIdMethod=(id) =>
    {
        setUserId(old => id);
        setUserIdFound(old => false);
    };

    // User Search Modal
//    const [productInputIndex,setProductInputIndex]=useState(null);
   const [userModalIsOpen,setUserModalIsOpen]=useState(false);
   const closeUserModal=()=>{
     setUserModalIsOpen(old => false);
     
   }
   const openUsersModal=(index) =>{
    setUserModalIsOpen(old => true);
    //  setProductInputIndex(index);
   }
    const handleUserSearchModalSubmit= async ({userId})=>{
   
        setUserId(old => userId);
        setUserIdFound(old => true);

      closeUserModal();
      
        
    }
    
    //Table Data
    const [page, setPage] = useState(0);
    const handleChangePage = (event,newPage) => 
    {
        setPage(newPage);
    };

    //Jwt and freeSession 
    const history=useNavigate();
    const jwt=useSelector(state => state.jwt);
    const dispatch=useDispatch();
    const headers = { headers: {
        "Authorization" : `Bearer ${jwt}`,
  
      } }
    const freeTokenFromSessionStorage = () => {
        dispatch(logout);
        saveLogoutInLocalStorage();
        history("/login");
      };

    //Update Modal 

    const [modalIsOpen,setmodalIsOpen]=useState(false);
    const [selectedPromoCode,setSelectedPromoCode]=useState({});
    const openUpdatePromoCodeModal=(promoCode) =>{
      setSelectedPromoCode(promoCode);
      setmodalIsOpen(true);
    }
    const closeUpdateModal=()=>{
      setmodalIsOpen(olde => false);
      
    }
    const handleUpdate= async ({data})=>{


      
      console.log("Promo Code Update Done");
      const endpoint=getEndPoint("/promoCode");
      const response = await axios.put(endpoint,data,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
        console.log("Update Error Response Status :");
        console.log(error.response);
   
      });
      console.log("Update Data Response :");
      console.log(response.data);
      await loadPromoCodes();
       

      closeUpdateModal();
      
        
    }

    //Loadings

    const loadPromoCodeOrders= async () => {
      const endpoint=getEndPoint("/promoCodeOrder?id="+promoCodeIdVariable+"&page="+promoCodePage);
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
   
      });
      console.log("promo codes Data");
      console.log(data.data);
      setPromoCodeOrdersList(old => data.data.content);
      setPromoCodeOrdersTotalElements(old => data.data.totalElements);

    }
    const loadPromoCodes= async() => {
      const endpoint=getEndPoint("/promoCode");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
   
      });

      setPromoCodesList(old => data.data);

    }
    const loadUsers= async() => {
        const endpoint=getEndPoint("/usersList");
        const data= await axios.get(endpoint,headers).catch(function (error) {
          if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
            console.log("You're not allowed buddy");
            freeTokenFromSessionStorage();
           
          }
     
        });
        setUsersList(old => data.data);
    
      }

    useEffect(()=>{
        loadUsers();
        loadPromoCodes();
    
   
      },[]);
      useEffect(() => {
        loadPromoCodeOrders();
      },[promoCodeIdVariable])



    return (<div>
   <UpdateModal  open={modalIsOpen} promoCode={selectedPromoCode} handleClose={closeUpdateModal} handleUpdate={handleUpdate} usersList={usersList} />
<UserSearchModal open={userModalIsOpen}   users={usersList} handleClose={closeUserModal} handleSubmit={handleUserSearchModalSubmit}  />


<Card className="searchCard" variant="outlined">
<CardContent>
<p  className="cornerText">New Promo Code </p>
<Grid container direction="row" justifyContent="space-around" alignItems="baseline">
 
<Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             Promo Code ID : 
            </Typography>
            <MyInput value={id} onChange={(event) => setId(event.target.value)} 
            placeholder="ID" 
    />
              </Grid>
              <Grid item >
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                ID User :
              </Typography>

    <span style={{"display":"flex"}}>
<MyInput found={userIdFound}  value={userId} placeholder="ID User" />
               <span style={{"width":"2px"}}/>
              <Button       style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }} variant="contained" onClick={() => openUsersModal()} ><SearchIcon style={{"fontSize":"28px"}}/></Button>
              </span>
          
            </Grid>

  </Grid>
  <br/>
  <hr/>
  <br/>
  <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
  <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Owner Share :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Owner Share</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Owner Share"
                  value={ownerShare}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setOwnerShare(event.target.value)}} 
                >      
  
             
                   {getDiscountValues()}
             
                </Select>
              </FormControl>
          
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              User Discount :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">User Discount</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="User Discount"
                  value={userDiscount}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setUserDiscount(event.target.value)}} 
                >      
  
             
                   {getDiscountValues()}
             
                </Select>
              </FormControl>
          
              </Grid>

  </Grid>
  <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
  <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Number Of Days To Expire :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Number Of Days To Expire</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Number Of Days To Expire"
                  value={promoCodeEndDate}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setPromoCodeEndDate(event.target.value)}} 
                >      
  
             
                   {getNumberOfDaysToExpire()}
             
                </Select>
              </FormControl>
          
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Enabled :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Enabled</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="User Discount"
                  value={promoCodeEnabled}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setPromoCodeEnabled(event.target.value)}} 
                >      
                <MenuItem  value={true}>True</MenuItem>
                <MenuItem  value={false}>False</MenuItem>
             
                </Select>
              </FormControl>
          
              </Grid>

  </Grid>
     
  <br/>
           <br/>
              <Button      style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }} onClick={createPromoCode} className={"normalButton2"} variant="contained" endIcon={<AddIcon />}>
              Créer
            </Button>
</CardContent>
</Card>
<br/>
<TableContainer className="tableContainerStyle"  component={Paper}>

<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">ID</TableCell>
      <TableCell className="filtertitle" align="left">User ID</TableCell>
      <TableCell className="filtertitle" align="left">Owner Share</TableCell>
     
      <TableCell className="filtertitle" align="left">User Discount</TableCell>
      <TableCell className="filtertitle" align="left">End Date</TableCell>
      <TableCell className="filtertitle" align="left">Enabled</TableCell>
      <TableCell  className="filtertitle" align="left">Modifier</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {promoCodesList
.map(promoCode => (
<TableRow
// key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
key={promoCode.id}
>
<TableCell component="th" scope="row" align="left">{promoCode.id}</TableCell>
<TableCell align="left">{promoCode.userId}</TableCell>
<TableCell  align="left">{promoCode.ownerShare} %</TableCell>
<TableCell  align="left">{promoCode.userDiscount} %</TableCell>
<TableCell align="left">{promoCode.endDate}</TableCell>

<TableCell align="left">
{promoCode.enabled ? <Online/> : <Offline/>}

</TableCell>
<TableCell align="left"><EditIcon onClick={() => {openUpdatePromoCodeModal(promoCode)}}  className="editIcon2"/></TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            promoCodesList.length
            }
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
<br/>
<Card className="searchCard" variant="outlined">
<CardContent>
<p  className="cornerText">Promo Code Order Filter </p>
<Grid container direction="row" justifyContent="space-around" alignItems="baseline">
  <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Promo Code Id :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Promo Code Id</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Promo Code Id"
                  value={promoCodeIdVariable}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setPromoCodeIdVariable(event.target.value)}} 
                >      
  
             
                   {promoCodesList.map(
                    (promoCode) => 
                    <MenuItem key={promoCode.id} value={promoCode.id}>{promoCode.id}</MenuItem>
                   )}
             
                </Select>
              </FormControl>
          
              </Grid>
      

  </Grid>

</CardContent>
</Card>
<br/>
<TableContainer className="tableContainerStyle"  component={Paper}>

<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">ID</TableCell>
      <TableCell className="filtertitle" align="left">Promo Code ID</TableCell>
      <TableCell className="filtertitle" align="left">Owner Id</TableCell>
      <TableCell className="filtertitle" align="left">User ID</TableCell>
    
      <TableCell className="filtertitle" align="left">User Discount</TableCell>
      <TableCell className="filtertitle" align="left">Owner Share</TableCell>
      <TableCell className="filtertitle" align="left">Total</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {promoCodeOrdersList
.map(promoCodeOrder => (
<TableRow
 key={promoCodeOrder.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{promoCodeOrder.id}</TableCell>
<TableCell align="left">{promoCodeOrder.promoCodeId}</TableCell>
<TableCell  align="left">{promoCodeOrder.ownerId}</TableCell>
<TableCell  align="left">{promoCodeOrder.userId}</TableCell>
<TableCell align="left">{promoCodeOrder.userDiscountTotal}</TableCell>
<TableCell align="left">{promoCodeOrder.ownerShareTotal}</TableCell>
<TableCell align="left">{promoCodeOrder.total}</TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            promoCodeOrdersList.length
            }
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
           onPageChange={handleChangePromoCodePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>


    </div>)
}

export default View;