import {Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Button,Slide,
  Card,CardContent, Grid ,Typography,TableContainer,Table
  ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
  Paper,Select,MenuItem,FormControl,InputLabel,Divider } from "@mui/material";
import InputUnstyled from '@mui/base/InputUnstyled';
import React, { useState,useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import {saveLogoutInLocalStorage,generateArrayOfMonths,generateArrayOfYears,generateDays, generateCookingFactors} from "../../../../helpers/constants";
import { styled } from '@mui/system';

//My Costum inputs
import MyInput from "../../../../globalComponents/MyInput";
import SmallInput from "../../../../globalComponents/SmallInput";

//Icons
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import RemoveIcon from '@mui/icons-material/Remove';

import ProductSearchModal from "../ProductSearchModal";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const UpdateModal=({open,handleClose,meal,handleUpdate,myLocals,myFoodList}) => {

  const [mealId,setMealId]=useState("");
  const [name,setName]=useState({
    en:"",
    fr:"",
    ar:"null"
  });
  const [type,setType]=useState("");
  const [protein,setProtein]=useState("");
  const [carbs,setCarbs]=useState("");
  const [fat,setFat]=useState("");
  // const [calories,setCalories]=useState("");
  const [cost,setCost]=useState("");
  const [sellPrice,setSellPrice]=useState("");
  const [isActive,setIsActive]=useState(false);
  const [image,setImage]=useState(null);


  const [locals,setLocals]=useState([]);
  const [foodList,setFoodList]=useState([]);

  const clearMealInput=() =>
  {
    setMealId("");
    setName({
      en:"",
      fr:"",
      ar:"null"
    });
    setType("");
    setProtein("");
    setCarbs("");
    setFat("");
    setCost("");
    setSellPrice("");
    setIsActive("");
    setImage("");
 

    setMealCalculatedProtein(null);
    setMealCalculatedCarbs(null);
    setMealCalculatedFat(null);
    setMealCalculatedSellPrice(null);

    setMealRecipeProductIndex(1);

    setRecipe([
      {
        found:null,
        index:0,
    
        mealId:"",
        name:"",
        type:"",
    
        proteinPer100g:null,
        carbsPer100g:null,
        fatPer100g:null,
    
    
        buyPricePer100g:null,
        sellPricePer100g:null,
    
        quantity:"",
      
        totalProtein:null,
        totalCarbs:null,
        totalFat:null,
        
        cost:null,
        total:null,
      }
    ]);


  }

  const recipeIsValid=()=> {

    if(recipeIsEmpty())
    {
      return true;
    }
    else
    {
      var recipeValid=true;

      for(var i=0;i<recipe.length;i++)
      {

        if(isNaN(recipe[i].total))
        {

          recipeValid=false;
          break;
        }

      }
  

      return recipeValid;
    }

  }

  const generateUpdatedData=()=>
  {

    if(recipeIsValid() && !isNaN(protein) && !isNaN(carbs) && !isNaN(fat) && !isNaN(sellPrice))
    {
   
      var totalCalories=0;
      totalCalories= (protein*4)+(carbs*4)+(fat*9);
      let data= {
        id:mealId,
        name:name,
        type:type,
        protein:protein,
        carbs:carbs,
        fat:fat,
        calories:totalCalories,
        cost: recipeIsEmpty()? null:cost,
        sellPrice:sellPrice,
        activated:isActive,
        recipe:[],
      }
      if(!recipeIsEmpty())
      {
        recipe.map((meal)=>{
          data.recipe.push(
            {
          
              mealId:meal.mealId,
              name:meal.name,
              type:meal.type,
      
              proteinPer100g:meal.proteinPer100g,
              carbsPer100g:meal.carbsPer100g,
              fatPer100g:meal.fatPer100g,
      
      
              buyPricePer100g:meal.buyPricePer100g,
              sellPricePer100g:meal.sellPricePer100g,
      
              quantity:meal.quantity,
            
              totalProtein:meal.totalProtein,
              totalCarbs:meal.totalCarbs,
              totalFat:meal.totalFat,
              
              cost:meal.cost,
              total:meal.total,
            }
          )
  
  
         });
      }
      return data;

    }
    else
    {
      return null;
    }

  }
  //Calculated Variables

  const [mealCalculatedProtein,setMealCalculatedProtein]=useState(null);
  const [mealCalculatedCarbs,setMealCalculatedCarbs]=useState(null);
  const [mealCalculatedFat,setMealCalculatedFat]=useState(null);
  const [mealCalculatedSellPrice,setMealCalculatedSellPrice]=useState(null);

  


  const calculateTotalAndMacros=(list)=>{
    let total=0;
    let cost=0;
    let protein=0;
    let carbs=0;
    let fat=0;
 
  
    for (var i = 0; i < list.length; i++) {
      if(list[i].total==null || isNaN(list[i].total) )
      {
        total=null;
        cost=null;
        protein=null;
        carbs=null;
        fat=null;
        break;
      }
      else
       {
        total+=list[i].total;
        cost+=list[i].cost;
        protein+=list[i].totalProtein;
        carbs+=list[i].totalCarbs;
        fat+=list[i].totalFat;

       }
        
    }
    setCost(old => cost);
    setMealCalculatedSellPrice(old => total);
    setMealCalculatedProtein(old => protein);
    setMealCalculatedCarbs(old => carbs);
    setMealCalculatedFat(old => fat);

  }

// Meal Recipee Variables and methods

const [recipe,setRecipe]=useState(
  [
  {
    found:null,
    index:0,

    mealId:"",
    name:"",
    type:"",

    proteinPer100g:null,
    carbsPer100g:null,
    fatPer100g:null,


    buyPricePer100g:null,
    sellPricePer100g:null,

    quantity:"",
  
    totalProtein:null,
    totalCarbs:null,
    totalFat:null,
    
    cost:null,
    total:null,
  }
]);

const recipeIsEmpty=()=>
{
  if(recipe.length<=1 && (recipe[0].quantity=="" || recipe[0].mealId=="" || recipe[0].quantity==null || recipe[0].mealId==null ))
  {
    return true;

  }
  else
  return false;


}

  const setMealRecipeElementQuantity=({index,value})=>{
    
    setRecipe((old) => {
      let mylist=[...old];

      var myQuantity=value/100;
      mylist[index].quantity=value;

    
 
      if(mylist[index].found)
      {
        mylist[index].totalProtein=myQuantity*mylist[index].proteinPer100g;
        mylist[index].totalCarbs=myQuantity*mylist[index].carbsPer100g;
        mylist[index].totalFat=myQuantity*mylist[index].fatPer100g;
        mylist[index].cost=myQuantity*mylist[index].buyPricePer100g;
        mylist[index].total=myQuantity*mylist[index].sellPricePer100g;
      }
      else
      {
      mylist[index].total=null;
      }
      calculateTotalAndMacros(mylist);
      return mylist;

    });
    

  }
  const [mealRecipeProductIndex,setMealRecipeProductIndex]=useState(1);
  const addProduct= ()=>{
      
    setRecipe( old =>
     {
       let mylist=[...old];
  
       mylist.push(
        {
          found:null,
          index:mealRecipeProductIndex,
  
          mealId:"",
          name:"",
          type:"",
  
          proteinPer100g:null,
          carbsPer100g:null,
          fatPer100g:null,
  
  
          buyPricePer100g:null,
          sellPricePer100g:null,
  
          quantity:"",
        
          totalProtein:null,
          totalCarbs:null,
          totalFat:null,
          
          cost:null,
          total:null,
        }
       );
    
       calculateTotalAndMacros(mylist);
     return mylist;

    }
    );
    setMealRecipeProductIndex(old => old+1);

    
  };
  const removeProduct=()=>{
    if(mealRecipeProductIndex>1)
    {
      setRecipe(
      (old) =>{
        let mylist=[...old];
        mylist.pop();
        calculateTotalAndMacros(mylist);
        return mylist;
      }
    );
    setMealRecipeProductIndex(old => old-1);
    }

  };





  //Food Search Modal Data
    // Food Search Modal
    const [productInputIndex,setProductInputIndex]=useState(null);
    const [productModalIsOpen,setProductModalIsOpen]=useState(false);
    const closeProductModal=()=>{
      setProductModalIsOpen(old => false);
      
    }
    const openProductModal=(index) =>{
      setProductModalIsOpen(old => true);
      setProductInputIndex(index);
    }
     const handleFoodSearchModalSubmit= async ({indexProduct,product})=>{
       setRecipe((old) => {
         let mylist=[...old];
         var myQuantity=null;
          
         
       if(mylist[indexProduct].quantity!=null && mylist[indexProduct].quantity!="")
       {
         myQuantity=mylist[indexProduct].quantity;
       }
       mylist[indexProduct]=product;
 
       if(myQuantity!=null && myQuantity!="")
       {
         mylist[indexProduct].quantity=myQuantity;
 
         mylist[indexProduct].totalProtein=myQuantity*mylist[indexProduct].proteinPer100g;
         mylist[indexProduct].totalCarbs=myQuantity*mylist[indexProduct].carbsPer100g;
         mylist[indexProduct].totalFat=myQuantity*mylist[indexProduct].fatPer100g;
         mylist[indexProduct].cost=myQuantity*mylist[indexProduct].buyPricePer100g;
         mylist[indexProduct].total=myQuantity*mylist[indexProduct].sellPricePer100g;
     
       }
           
         calculateTotalAndMacros(mylist);
         return mylist;
 
       });
 
       //closeUpdateModal();
       closeProductModal();
       
         
     }


  //Rest
  const changeStatusFilter=(isActiveProp)=>
  {
    setIsActive((old) => isActiveProp);
  }

 

 useEffect(
   () => {
 
    setMealId(old => meal.id);
    if(meal.name!=null)
    setName(old => meal.name);
    setType(old => meal.type);
    setProtein(old => meal.protein);
    setCarbs(old => meal.carbs);
    setFat(old => meal.fat);
    setCost(old => meal.cost);
    setSellPrice(old => meal.sellPrice);
    setIsActive(old => meal.activated);
    setImage(old => null);
    // if(meal.recipe!=null)
    // {
    //   setRecipe(old => meal.recipe);
    // }
   


    setMealCalculatedSellPrice(old => null);
    setMealCalculatedProtein(old => null);
    setMealCalculatedCarbs(old => null);
    setMealCalculatedFat(old => null);
    setLocals(old => myLocals);
    setFoodList(old=> myFoodList);
    // if(meal.recipe!=null)
    // setMealRecipeProductIndex(old =>meal.recipe.length);
 

   },
   [meal]
 );



 
  

 
  // const freeTokenFromSessionStorage = () => {
  //   dispatch(logout);
  //   saveLogoutInLocalStorage();
  //   history("/login");
  // };
    return (
       <div>
         
         <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      
      >
         <ProductSearchModal open={productModalIsOpen} index={productInputIndex} locals={locals} products={foodList} handleClose={closeProductModal} handleSubmit={handleFoodSearchModalSubmit}  />

        <DialogTitle className="cornerText"> Modify Meal : {mealId} </DialogTitle>
        <DialogContent className="DialogContainer" >
        <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
        <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
           Meal Name :
            </Typography>
            <Grid container direction="column">
              <Grid item>
              <MyInput value={name.en} onChange={(event) => setName(old => {
                old.en=event.target.value;
                return old;
              })
            } 
            placeholder="English" 
          />
              </Grid>
              <Grid item>
              <MyInput value={name.fr} onChange={(event) => setName(old => {
                old.fr=event.target.value;
                return old;
              })
            } placeholder="Francais" 
          />
              </Grid>
              <Grid item>
              <MyInput value={name.ar} onChange={(event) => setName(old => {
                old.ar=event.target.value;
                return old;
              })
            } placeholder="العربية" 
          />
              </Grid>
 
              </Grid>
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Meal Type :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">MealType</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Produit"
                  value={type}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setType(event.target.value)}} 
                >             
                   <MenuItem  value={"Pizza"}>Pizza</MenuItem>
                   <MenuItem  value={"Tacos"}>Tacos</MenuItem>
                   <MenuItem  value={"Burger"}>Burger</MenuItem>
                   <MenuItem  value={"Panini"}>Panini</MenuItem>
                   <MenuItem  value={"Pasticcio"}>Pasticcio</MenuItem>
                   <MenuItem  value={"Salad"}>Salad</MenuItem>
                   <MenuItem  value={"Juice"}>Juice</MenuItem>
                   <MenuItem  value={"WheyJuice"}>WheyJuice</MenuItem>
                   
                </Select>
              </FormControl>
          
              </Grid>
         
            
              </Grid>
              <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Total Protein :
            </Typography>
            <MyInput value={protein} onChange={(event) =>{setProtein(event.target.value)}} 
            placeholder=" Total Protein" 
         />
            {mealCalculatedProtein!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
              
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   {parseFloat(mealCalculatedProtein).toFixed(2)} g
                  </Typography>
                
                  
                  </Grid>
                  </Grid>
           
                  
               }
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Total Carbs :
            </Typography>
            <MyInput value={carbs} onChange={(event) =>{setCarbs(event.target.value)}} 
            placeholder="Total Carbs" 
      />
      {mealCalculatedCarbs!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
              
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   {parseFloat(mealCalculatedCarbs).toFixed(2)} g
                  </Typography>
                
                  
                  </Grid>
                  </Grid>
           
                  
               }
              </Grid>
             
            
            
             </Grid>
             <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
             <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Total Fat :
            </Typography>
            <MyInput value={fat} onChange={(event) =>{setFat(event.target.value)}} 
            placeholder="Total Fat" 
          />
          {mealCalculatedFat!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
              
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   {parseFloat(mealCalculatedFat).toFixed(2)} g
                  </Typography>
                
                  
                  </Grid>
                  </Grid>
           
                  
               }
              </Grid>
         
             
          </Grid>
          <Divider  style={{"margin-right":"auto","margin-left":"auto","width":"20rem","margin-bottom":"5px","margin-top":"10px"}} orientation="horizontal" variant="middle"/>
          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Meal Recipe 
            </Typography>
            </Grid>
          </Grid>
          {recipe.map((mealRecipeElement)=>
            <Grid key={mealRecipeElement.index} container direction="row" justifyContent="space-around" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                ID Meal :
              </Typography>

    <span style={{"display":"flex"}}>
<MyInput found={(mealRecipeElement.mealId=="")? null : mealRecipeElement.found}  value={mealRecipeElement.mealId} placeholder="ID Meal" />
               <span style={{"width":"2px"}}/>
              <Button       style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }} variant="contained" onClick={() => openProductModal(mealRecipeElement.index)} ><SearchIcon style={{"fontSize":"28px"}}/></Button>
              </span>
              {mealRecipeElement.total!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
                  {isNaN(mealRecipeElement.total)?
                   <Typography className="errorlittleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   Quantité invalide !
                   </Typography>
                  :
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   cost: {mealRecipeElement.cost} DH,  Total : {mealRecipeElement.total} DH
                  </Typography>
                  }
                  
                  </Grid>
                  </Grid>
           
                  
               }
            </Grid>
           
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
               Quantity : 
              </Typography>
              <MyInput   onChange={(event) => setMealRecipeElementQuantity({index:mealRecipeElement.index,value:event.target.value})} 
              // placeholder={mealRecipeElement.quantity}
             />
            </Grid>
            </Grid>
          )}
               <br/>
            <Grid  container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid  item>
                   <AddIcon onClick={addProduct} fontSize="large" className="addProductIconContainer"/>
              </Grid>
              <Grid  item>
                   <RemoveIcon onClick={removeProduct} fontSize="large" className="removeProductIconContainer" />
              </Grid>
            </Grid>
          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
               SellPrice:
            </Typography>
            <MyInput value={sellPrice} onChange={(event) =>{setSellPrice(event.target.value)}} 
            placeholder="Meal SellPrice" 
           />
             {mealCalculatedSellPrice!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
              
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   Cost :{parseFloat(cost).toFixed(2)} DH, SellPrice : {parseFloat(mealCalculatedSellPrice).toFixed(2)}
                  </Typography>
                
                  
                  </Grid>
                  </Grid>
           
                  
               }
              </Grid>
          </Grid>
     
              <Grid item xs={12} md={6}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Est Activé  :
            </Typography>
              <Grid id="isActiveContainer" container spacing={0}>
                <Grid item md={6} xs={6}>
                  {isActive ? 
                  <Button onClick={() => {changeStatusFilter(true)}} id="completedButton">Activated</Button>
                  :
                  <Button onClick={() => {changeStatusFilter(true)}} id="completedButtonDisabled">Activated</Button>
                }
                </Grid>
                <Grid item md={6} xs={6}>
                {!isActive ? 
                  <Button onClick={() => {changeStatusFilter(false)}} id="failedButton">Deactivated</Button>
                  :
                  <Button onClick={() => {changeStatusFilter(false)}} id="failedButtonDisabled">Deactivated</Button>
                }
               
                </Grid>
                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
           Meal Image :
            </Typography>
            <Button
            color="warning" 
                        variant="contained"
                        component="label" 
                         className={image!==null? "insertPhotoButton":""    }
                      
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                         onChange={(event) => setImage(old => event.target.files[0])}
                        />
                      </Button>
              </Grid>
           
              </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="fermerButton" onClick={() => {
            clearMealInput();
            handleClose();
          }}>Fermer</Button>
          <Button id="modifierButton" onClick={
           () => {
            var data=generateUpdatedData();

            if(data!=null)
            {
              clearMealInput();
              handleUpdate({
                data:data,
                image:image,
              });
            }
         
           }
            }>Modifier</Button>
        </DialogActions>
      </Dialog>
       </div>
    )
};
const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  600: '#0072E5',
};
const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};
const StyledInputElement = styled('input')(
  ({ theme }) => `
  width: 320px;
  height:38px;
  font-size: 1rem;
 
  font-weight:  500;
  line-height: 1.5;
  color: #3b3b3b;
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 5px;
  padding: 8px 8px;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }
`,
);
export default UpdateModal;
