import {Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Button,Slide,
  Card,CardContent, Grid ,Typography,TableContainer,Table
  ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
  Paper,Select,MenuItem,FormControl,InputLabel } from "@mui/material";
import InputUnstyled from '@mui/base/InputUnstyled';
import React, { useState,useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import {saveLogoutInLocalStorage,generateArrayOfMonths,generateArrayOfYears,generateDays, generateCookingFactors} from "../../../../helpers/constants";
import { styled } from '@mui/system';

//My Costum inputs
import MyInput from "../../../../globalComponents/MyInput";
import SmallInput from "../../../../globalComponents/SmallInput";

//Icons
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import EditIcon from '@mui/icons-material/Edit';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const UpdateModal=({open,handleClose,food,handleUpdate}) => {

  const [foodId,setFoodId]=useState("");

  const [nameEn,setNameEn]=useState("");
  const [nameFr,setNameFr]=useState("");
  const [nameAr,setNameAr]=useState("");
  const [type,setType]=useState("");
  const [buyPricePer100g,setBuyPricePer100g]=useState("");
  const [sellPricePer100g,setSellPricePer100g]=useState("");
  const [proteinPer100g,setProteinPer100g]=useState("");
  const [carbsPer100g,setCarbsPer100g]=useState("");
  const [fatPer100g,setFatPer100g]=useState("");
  const [cookingFactor,setCookingFactor]=useState("");
  const [image,setImage]=useState("");
  const [foodImage,setFoodImage]=useState(null);
  const [isActive,setIsActive]=useState(false);

  const changeStatusFilter=(isActiveProp)=>
  {
    setIsActive((old) => isActiveProp);
  }
  let date=null;
 

 useEffect(
   () => {
 
    setFoodId(old => food.id);
    if(food.name!=null)
    {
      setNameEn(old => food.name.en);
      setNameFr(old => food.name.fr);
      setNameAr(old => food.name.ar);
    }
    setType(old => food.type);
    setProteinPer100g(old => food.proteinPer100g);
    setCarbsPer100g(old => food.carbsPer100g);
    setFatPer100g(old => food.fatPer100g);
    setCookingFactor(old => food.cookingFactor);
    setBuyPricePer100g(old => food.buyPricePer100g);
    setSellPricePer100g(old => food.sellPricePer100g);
    setIsActive(old => food.activated);
    setFoodImage(null);
    setImage(old => food.image);

   },
   [food]
 );



 
  
    const updatedData=() => {
      return {
        id:food.id,
        name:{
          "en":nameEn,
          "fr":nameFr,
          "ar":nameAr,
        },
        type:type,
        proteinPer100g:proteinPer100g,
        carbsPer100g:carbsPer100g,
        fatPer100g:fatPer100g,
        buyPricePer100g:buyPricePer100g,
        sellPricePer100g:sellPricePer100g,
        cookingFactor:cookingFactor,
        activated:isActive,
        foodImage:foodImage,
        image:image,
      }
    }
 
  // const freeTokenFromSessionStorage = () => {
  //   dispatch(logout);
  //   saveLogoutInLocalStorage();
  //   history("/login");
  // };
    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      
      >
        <DialogTitle className="cornerText"> Modify Food : {foodId} </DialogTitle>
        <DialogContent className="DialogContainer" >
        <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
           Food Name :
            </Typography>
              <Grid container direction="column">
               <Grid item>
               <MyInput value={nameEn} onChange={(event) => setNameEn(event.target.value)} 
            placeholder="English" 
          />
               </Grid>
               <Grid item>
               <MyInput value={nameFr} onChange={(event) => setNameFr(event.target.value)} 
            placeholder="Francais" 
          />
               </Grid>
               <Grid item>
               <MyInput value={nameAr} onChange={(event) => setNameAr(event.target.value)} 
            placeholder="العربية" 
          />
               </Grid>
              </Grid>
               </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Food Type :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">TypeProduit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Produit"
                  value={type}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setType(event.target.value)}} 
                >             
                   <MenuItem  value={"Fat"}>Fat</MenuItem>
                   <MenuItem  value={"Protein"}>Protein</MenuItem>
                   <MenuItem  value={"Carbs"}>Carbs</MenuItem>
                   
                </Select>
              </FormControl>
          
              </Grid>
            
              </Grid>
              <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Protein Per 100g :
            </Typography>
            <MyInput value={proteinPer100g} onChange={(event) =>{setProteinPer100g(event.target.value)}} 
            placeholder="Protein Per 100g" 
         />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Carbs Per 100g :
            </Typography>
            <MyInput value={carbsPer100g} onChange={(event) =>{setCarbsPer100g(event.target.value)}} 
            placeholder="Carbs Per 100g " 
      />
              </Grid>
            
             </Grid>
             <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
             <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Fat Per 100g :
            </Typography>
            <MyInput value={fatPer100g} onChange={(event) =>{setFatPer100g(event.target.value)}} 
            placeholder="Fat per 100g" 
          />
              </Grid>
             <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Cooking Factor :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">TypeProduit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Cooking Factor"
                  value={cookingFactor}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setCookingFactor(event.target.value)}} 
                >       
                {generateCookingFactors().map((value) => (
                  <MenuItem  value={value}>{value}</MenuItem>
                ))}      
                 
                   
                </Select>
              </FormControl>
          
              </Grid>
             
          </Grid>
          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              BuyPrice Per 100g:
            </Typography>
            <MyInput value={buyPricePer100g} onChange={(event) =>{setBuyPricePer100g(event.target.value)}} 
            placeholder="BuyPrice Per 100g" 
           />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              SellPrice Per 100g:
            </Typography>
            <MyInput value={sellPricePer100g} onChange={(event) =>{setSellPricePer100g(event.target.value)}} 
            placeholder="SellPrice Per 100g" 
           />
              </Grid>
         
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Est Activé  :
            </Typography>
              <Grid id="isActiveContainer" container spacing={0}>
                <Grid item md={6} xs={6}>
                  {isActive ? 
                  <Button onClick={() => {changeStatusFilter(true)}} id="completedButton">Activated</Button>
                  :
                  <Button onClick={() => {changeStatusFilter(true)}} id="completedButtonDisabled">Activated</Button>
                }
                </Grid>
                <Grid item md={6} xs={6}>
                {!isActive ? 
                  <Button onClick={() => {changeStatusFilter(false)}} id="failedButton">Deactivated</Button>
                  :
                  <Button onClick={() => {changeStatusFilter(false)}} id="failedButtonDisabled">Deactivated</Button>
                }
               
                </Grid>
                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
           Food Image :
            </Typography>
            <Button
            // color="warning" 
            
                        variant="contained"
                        component="label" 
                         className={foodImage!==null? "insertPhotoButton3":"normalButton3"    }
                      
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                         onChange={(event) => setFoodImage(old => event.target.files[0])}
                        />
                      </Button>
              </Grid>
              </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
          <Button id="modifierButton" onClick={
           () => {
            handleUpdate({
              data:updatedData(),
            });
           }
            }>Modifier</Button>
        </DialogActions>
      </Dialog>
    )
};
const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  600: '#0072E5',
};
const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};
const StyledInputElement = styled('input')(
  ({ theme }) => `
  width: 320px;
  height:38px;
  font-size: 1rem;
 
  font-weight:  500;
  line-height: 1.5;
  color: #3b3b3b;
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 5px;
  padding: 8px 8px;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }
`,
);
export default UpdateModal;
