
import "./mealManagement.css"

//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";
import { useEffect, useState } from "react";

//Mui 
import { Card,CardContent, Grid ,Typography,Button,TableContainer,Table
  ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
  Paper,Select,MenuItem,FormControl,InputLabel,Divider } from "@mui/material";
//Icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { generateCookingFactors, getEndPoint, saveLogoutInLocalStorage ,getCompressedImage} from "../../helpers/constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

//Redux 
import { logout } from "../../redux/auth/authactions";
import { MealImage } from "../../globalComponents/MyMealImage";
import Online from "./components/Online";
import Offline from "./components/Offline";
import UpdateModal from "./components/UpdateModal";

//Modals 
//import UpdateModal from "./components/UpdateModal";
import ProductSearchModal from "./components/ProductSearchModal";

const View= () =>{
     //Locals
     const [locals,setLocals]=useState([]);
    

     const [foodList,setFoodList]=useState([]);
    // Create new Meal
    const [name,setName]=useState("");
    const [type,setType]=useState("");
   //Cost
    const [mealId,setMealId]=useState("");
    const [mealNameEn,setMealNameEn]=useState("");
    const [mealNameFr,setMealNameFr]=useState("");
    const [mealNameAr,setMealNameAr]=useState("");
    const [mealType,setMealType]=useState("");
    const [mealImage,setMealImage]=useState(null);

    //Macros
    const [mealProtein,setMealProtein]=useState(null);
    const [mealCarbs,setMealCarbs]=useState(null);
    const [mealFat,setMealFat]=useState(null);

    const [mealCalculatedProtein,setMealCalculatedProtein]=useState(null);
    const [mealCalculatedCarbs,setMealCalculatedCarbs]=useState(null);
    const [mealCalculatedFat,setMealCalculatedFat]=useState(null);
 

    //
    const [mealCost,setMealCost]=useState(null);
    const [mealCalculatedSellPrice,setMealCalculatedSellPrice]=useState(null);
    const [mealSellPrice,setMealSellPrice]=useState(null);  
    

    //Meal recipes
    const addProduct= ()=>{
      
      setMealRecipe( old =>
       {
         let mylist=[...old];
    
         mylist.push(
          {
            found:null,
            index:mealRecipeProductIndex,
    
            mealId:"",
            name:"",
            type:"",
    
            proteinPer100g:null,
            carbsPer100g:null,
            fatPer100g:null,
    
    
            buyPricePer100g:null,
            sellPricePer100g:null,
    
            quantity:"",
          
            totalProtein:null,
            totalCarbs:null,
            totalFat:null,
            
            cost:null,
            total:null,
          }
         );
      
         calculateTotalAndMacros(mylist);
       return mylist;

      }
      );
      setMealRecipeProductIndex(old => old+1);

      
    };
    const removeProduct=()=>{
      if(mealRecipeProductIndex>1)
      {
        setMealRecipe(
        (old) =>{
          let mylist=[...old];
          mylist.pop();
          calculateTotalAndMacros(mylist);
          return mylist;
        }
      );
      setMealRecipeProductIndex(old => old-1);
      }

    };

    const [mealRecipe,setMealRecipe]=useState(
      [
      {
        found:null,
        index:0,

        mealId:"",
        name:"",
        type:"",

        proteinPer100g:null,
        carbsPer100g:null,
        fatPer100g:null,


        buyPricePer100g:null,
        sellPricePer100g:null,

        quantity:"",
      
        totalProtein:null,
        totalCarbs:null,
        totalFat:null,
        
        cost:null,
        total:null,
      }
    ]);
    const [mealRecipeProductIndex,setMealRecipeProductIndex]=useState(1);

   // Food Search Modal
   const [productInputIndex,setProductInputIndex]=useState(null);
   const [productModalIsOpen,setProductModalIsOpen]=useState(false);
   const closeProductModal=()=>{
     setProductModalIsOpen(old => false);
     
   }
   const openProductModal=(index) =>{
     setProductModalIsOpen(old => true);
     setProductInputIndex(index);
   }
    const handleFoodSearchModalSubmit= async ({indexProduct,product})=>{
      setMealRecipe((old) => {
        let mylist=[...old];
        var myQuantity=null;
         
        
      if(mylist[indexProduct].quantity!=null && mylist[indexProduct].quantity!="")
      {
        myQuantity=mylist[indexProduct].quantity;
      }
      mylist[indexProduct]=product;

      if(myQuantity!=null && myQuantity!="")
      {
        mylist[indexProduct].quantity=myQuantity;

        mylist[indexProduct].totalProtein=myQuantity*mylist[indexProduct].proteinPer100g;
        mylist[indexProduct].totalCarbs=myQuantity*mylist[indexProduct].carbsPer100g;
        mylist[indexProduct].totalFat=myQuantity*mylist[indexProduct].fatPer100g;
        mylist[indexProduct].cost=myQuantity*mylist[indexProduct].buyPricePer100g;
        mylist[indexProduct].total=myQuantity*mylist[indexProduct].sellPricePer100g;
    
      }
          
        calculateTotalAndMacros(mylist);
        return mylist;

      });

      //closeUpdateModal();
      closeProductModal();
      
        
    }
    
    const setMealRecipeElementQuantity=({index,value})=>{
      setMealRecipe((old) => {
        let mylist=[...old];
        console.log("List :"+mylist);
        console.log("List Element ("+index+") :"+mylist[index]);
        var myQuantity=value/100;
        mylist[index].quantity=value;

      
   
        if(mylist[index].found)
        {
          mylist[index].totalProtein=myQuantity*mylist[index].proteinPer100g;
          mylist[index].totalCarbs=myQuantity*mylist[index].carbsPer100g;
          mylist[index].totalFat=myQuantity*mylist[index].fatPer100g;
          mylist[index].cost=myQuantity*mylist[index].buyPricePer100g;
          mylist[index].total=myQuantity*mylist[index].sellPricePer100g;
        }
        else
        {
        mylist[index].total=null;
        }
        calculateTotalAndMacros(mylist);
        return mylist;

      });
      

    }

    const calculateTotalAndMacros=(list)=>{
      let total=0;
      let cost=0;
      let protein=0;
      let carbs=0;
      let fat=0;
   
    
      for (var i = 0; i < list.length; i++) {
        if(list[i].total==null || isNaN(list[i].total) )
        {
          total=null;
          cost=null;
          protein=null;
          carbs=null;
          fat=null;
          break;
        }
        else
         {
          total+=list[i].total;
          cost+=list[i].cost;
          protein+=list[i].totalProtein;
          carbs+=list[i].totalCarbs;
          fat+=list[i].totalFat;

         }
          
      }
      setMealCost(old => cost);
      setMealCalculatedSellPrice(old => total);
      setMealCalculatedProtein(old => protein);
      setMealCalculatedCarbs(old => carbs);
      setMealCalculatedFat(old => fat);

    }
    const updateMeal= async (myMeal)=>{
     

    }
    const createMeal= async()=>{
      if(mealCalculatedSellPrice!=null && mealCost!=null && mealImage!=null)
      {
       var totalCalories=0;
       totalCalories= (mealProtein*4)+(mealCarbs*4)+(mealFat*9);
       let formData=new FormData();
       let data={
          id:mealId,
          name:{
            "en":mealNameEn,
            "fr":mealNameFr,
            "ar":mealNameAr,
          },
          type:mealType,
         
          protein:mealProtein,
          carbs:mealCarbs,
          fat:mealFat,
          calories:totalCalories,

          cost:mealCost,
          sellPrice:mealSellPrice,

          activated:true,
       
          recipe:[]
     
       };

       mealRecipe.map((meal)=>{
        data.recipe.push(
          {
        
            mealId:meal.mealId,
            name:meal.name,
            type:meal.type,
    
            proteinPer100g:meal.proteinPer100g,
            carbsPer100g:meal.carbsPer100g,
            fatPer100g:meal.fatPer100g,
    
    
            buyPricePer100g:meal.buyPricePer100g,
            sellPricePer100g:meal.sellPricePer100g,
    
            quantity:meal.quantity,
          
            totalProtein:meal.totalProtein,
            totalCarbs:meal.totalCarbs,
            totalFat:meal.totalFat,
            
            cost:meal.cost,
            total:meal.total,
          }
        )


       });
    
   
    
      
       formData.append("mealData",JSON.stringify(data));

       var compressedImage=await getCompressedImage(mealImage);
       formData.append("mealImage",compressedImage);
 
    const endpoint=getEndPoint("/createMeal");
       const response = await axios.post(endpoint,formData,headers).catch(function (error) {
         if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
 
           freeTokenFromSessionStorage();
          
         }
    
       });
       if(response!=null)
       {
        clearCreateMealInput();
        loadMeals();

       }
       
       
      
 
     }
 
     }
    const clearCreateMealInput=()=>{

      setName(old =>"");
      setType(old =>"");

      setMealId(old =>"");
      setMealNameEn(old =>"");
      setMealNameFr(old =>"");
      setMealNameAr(old =>"");
      setMealType(old =>"");
      setMealImage(old =>null);

      setMealProtein(old =>"");
      setMealCarbs(old =>"");
      setMealFat(old =>"");
    

      setMealCost(old =>null);
      setMealSellPrice(old =>null);

      setMealCarbs(old => null);
      setMealProtein(old => null);
      setMealFat(old => null);
      setMealSellPrice(old => "");
      setMealCalculatedSellPrice(old => null);
      setMealCalculatedCarbs(old => null);
      setMealCalculatedProtein(old => null);
      setMealCalculatedFat(old => null);



      setMealRecipe(old =>  [
        {
          found:null,
          index:0,
  
          mealId:"",
          name:"",
          type:"",
  
          proteinPer100g:null,
          carbsPer100g:null,
          fatPer100g:null,
  
  
          buyPricePer100g:null,
          sellPricePer100g:null,
  
          quantity:"",
        
          totalProtein:null,
          totalCarbs:null,
          totalFat:null,
          
          cost:null,
          total:null,
        }
      ]);
      setMealRecipeProductIndex(1);

     
    
    }

    // Filtres Part
    const [typeValue,setTypeValue]=useState("ALL");

    const [search,setSearch]= useState("");

    //Table Part 
     const [mealList,setMealList]=useState([]);
  
     const [page, setPage] = useState(0);
    const handleChangePage = (event,newPage) => 
    {
        setPage(newPage);
    };


    //Jwt and freeSession 
    const history=useNavigate();
    const jwt=useSelector(state => state.jwt);
    const dispatch=useDispatch();
    const headers = { headers: {
        "Authorization" : `Bearer ${jwt}`,
  
      } }
    const freeTokenFromSessionStorage = () => {
        dispatch(logout);
        saveLogoutInLocalStorage();
        history("/login");
      };

    //Methods 
  
  

    // Upadat Modal 
      const [selectedMeal,setSelectedMeal]=useState({});
      const [modalIsOpen,setModalIsOpen]=useState(false);
    const openUpdateModal=(meal) =>{
      setSelectedMeal(meal);
      setModalIsOpen(true);
    }
    const closeUpdateModal=()=>{
      setModalIsOpen(olde => false);
      
    }
    const handleUpdate= async ({data,image})=>{
      // let formData=new FormData();
      // const myData={
      //   id:data.id,
      //   name:data.name,
      //   type:data.type,
      //   proteinPer100g:data.proteinPer100g,
      //   carbsPer100g:data.carbsPer100g,
      //   fatPer100g:data.fatPer100g,
      //   buyPricePer100g:data.buyPricePer100g,
      //   sellPricePer100g:data.sellPricePer100g,
      //   cookingFactor:data.cookingFactor,
      //   image:data.image,
      //   activated:data.activated,
      // }
      // formData.append("data",JSON.stringify(myData));
      // formData.append("image",data.foodImage);
      // const endpoint=getEndPoint("/update");
      // const response = await axios.put(endpoint,formData,headers).catch(function (error) {
      //   if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
      //     console.log("You're not allowed buddy");
      //     freeTokenFromSessionStorage();
         
      //   }
      //   console.log("Update Error Response Status :");
      //   console.log(error.response);
   
      // });
      // if(response!=null && response.data!=null)
      // {
      //   loadFoods();
      // }
      let formData=new FormData();
      formData.append("mealData",JSON.stringify(data));

      var compressedImage=await getCompressedImage(image);
      formData.append("mealImage",compressedImage);

   const endpoint=getEndPoint("/updateMeal");
      const response = await axios.put(endpoint,formData,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {

          freeTokenFromSessionStorage();
         
        }
   
      });
      if(response!=null)
      {
       loadMeals();

      }

      closeUpdateModal();
      
        
    }
    //Loads 
    const loadLocals= async() => {
      const endpoint=getEndPoint("/Local");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          freeTokenFromSessionStorage();
         
        }
   
      });
      setLocals(old => data.data);

    }
    const loadMeals=async() => {
      const endpoint=getEndPoint("/MealList");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          freeTokenFromSessionStorage();
         
        }
   
      });
      setMealList(old => data.data);

    }
    const loadFoods=async()=>{
      const endpoint=getEndPoint("/foodList");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          console.log("You're not allowed buddy");
          freeTokenFromSessionStorage();
         
        }
   
      });
      

      if(data!=null)
      {
        console.log("FoodList :");
        console.log(data.data);
          setFoodList((old) => data.data);

      }

  };
 
  useEffect(()=> {
    loadMeals();
    loadFoods();

  },[]);

    return (<div >
      <UpdateModal  open={modalIsOpen} meal={selectedMeal} handleClose={closeUpdateModal} handleUpdate={handleUpdate} myLocals={locals} myFoodList={foodList} />
      <ProductSearchModal open={productModalIsOpen} index={productInputIndex} locals={locals} products={foodList} handleClose={closeProductModal} handleSubmit={handleFoodSearchModalSubmit}  />

     <Card className="creationCard" variant="outlined">
<CardContent>
          <p  className="cornerText">New Meal </p>
         
          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
           ID :
            </Typography>
            <MyInput value={mealId} onChange={(event) => setMealId(event.target.value)} 
            placeholder="ID" 
          />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
           Meal Name :
            </Typography>
            <Grid container direction="column">
              <Grid item>
              <MyInput value={mealNameEn} onChange={(event) => setMealNameEn(event.target.value)} 
            placeholder="English" 
          />
              </Grid>
              <Grid item>
              <MyInput value={mealNameFr} onChange={(event) => setMealNameFr(event.target.value)} 
            placeholder="Francais" 
          />
              </Grid>
              <Grid item>
              <MyInput value={mealNameAr} onChange={(event) => setMealNameAr(event.target.value)} 
            placeholder="العربية" 
          />
              </Grid>
 
              </Grid>
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Meal Type :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">MealType</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Produit"
                  value={mealType}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setMealType(event.target.value)}} 
                >             
                 
                   <MenuItem  value={"Pizza"}>Pizza</MenuItem>
                   <MenuItem  value={"Tacos"}>Tacos</MenuItem>
                   <MenuItem  value={"Burger"}>Burger</MenuItem>
                   <MenuItem  value={"Panini"}>Panini</MenuItem>
                   <MenuItem  value={"Pasticcio"}>Pasticcio</MenuItem>
                   <MenuItem  value={"Salad"}>Salad</MenuItem>
                   <MenuItem  value={"Juice"}>Juice</MenuItem>
                   <MenuItem  value={"WheyJuice"}>WheyJuice</MenuItem>
                   
                </Select>
              </FormControl>
          
              </Grid>
            
              </Grid>
              <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Total Protein :
            </Typography>
            <MyInput value={mealProtein} onChange={(event) =>{setMealProtein(event.target.value)}} 
            placeholder=" Total Protein" 
         />
            {mealCalculatedProtein!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
              
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   {parseFloat(mealCalculatedProtein).toFixed(2)} g
                  </Typography>
                
                  
                  </Grid>
                  </Grid>
           
                  
               }
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Total Carbs :
            </Typography>
            <MyInput value={mealCarbs} onChange={(event) =>{setMealCarbs(event.target.value)}} 
            placeholder="Total Carbs" 
      />
      {mealCalculatedCarbs!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
              
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   {parseFloat(mealCalculatedCarbs).toFixed(2)} g
                  </Typography>
                
                  
                  </Grid>
                  </Grid>
           
                  
               }
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Total Fat :
            </Typography>
            <MyInput value={mealFat} onChange={(event) =>{setMealFat(event.target.value)}} 
            placeholder="Total Fat" 
          />
          {mealCalculatedFat!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
              
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   {parseFloat(mealCalculatedFat).toFixed(2)} g
                  </Typography>
                
                  
                  </Grid>
                  </Grid>
           
                  
               }
              </Grid>
             </Grid>
             <Divider  style={{"margin-right":"auto","margin-left":"auto","width":"20rem","margin-bottom":"5px","margin-top":"10px"}} orientation="horizontal" variant="middle"/>
          <Grid container direction="row" justifyContent="center" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Meal Recipe 
            </Typography>
            </Grid>
            </Grid>
      
          {mealRecipe.map((mealRecipeElement)=>
            <Grid key={mealRecipeElement.index} container direction="row" justifyContent="space-around" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                ID Meal :
              </Typography>

    <span style={{"display":"flex"}}>
<MyInput found={(mealRecipeElement.mealId=="")? null : mealRecipeElement.found}  value={mealRecipeElement.mealId} placeholder="ID Meal" />
               <span style={{"width":"2px"}}/>
              <Button       style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }} variant="contained" onClick={() => openProductModal(mealRecipeElement.index)} ><SearchIcon style={{"fontSize":"28px"}}/></Button>
              </span>
              {mealRecipeElement.total!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
                  {isNaN(mealRecipeElement.total)?
                   <Typography className="errorlittleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   Quantité invalide !
                   </Typography>
                  :
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   cost: {mealRecipeElement.cost} DH,  Total : {mealRecipeElement.total} DH
                  </Typography>
                  }
                  
                  </Grid>
                  </Grid>
           
                  
               }
            </Grid>
           
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
               Quantity : 
              </Typography>
              <MyInput  onChange={(event) => setMealRecipeElementQuantity({index:mealRecipeElement.index,value:event.target.value})} 
              placeholder="Quantity" 
             />
            </Grid>
            </Grid>
          )}
          
          <br/>
            <Grid  container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid  item>
                   <AddIcon onClick={addProduct} fontSize="large" className="addProductIconContainer"/>
              </Grid>
              <Grid  item>
                   <RemoveIcon onClick={removeProduct} fontSize="large" className="removeProductIconContainer" />
              </Grid>
            </Grid>
            <Divider  style={{"margin-right":"auto","margin-left":"auto","width":"20rem","margin-bottom":"5px","margin-top":"10px"}} orientation="horizontal" variant="middle"/>
            
             <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
            
           
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Meal SellPrice:
            </Typography>
            <MyInput value={mealSellPrice} onChange={(event) =>{setMealSellPrice(event.target.value)}} 
            placeholder="Meal SellPrice" 
           />
             {mealCalculatedSellPrice!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
              
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   Cost :{parseFloat(mealCost).toFixed(2)} DH, SellPrice : {parseFloat(mealCalculatedSellPrice).toFixed(2)}
                  </Typography>
                
                  
                  </Grid>
                  </Grid>
           
                  
               }
              </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
           Meal Image :
            </Typography>
            <Button
       
       style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }}
                        variant="contained"
                        component="label" 
                         className={mealImage!==null? "insertPhotoButton":""    }
                      
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                         onChange={(event) => setMealImage(old => event.target.files[0])}
                        />
                      </Button>
              </Grid>
              </Grid>
       
           <br/>
           <br/>
              <Button      style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }} onClick={createMeal} className={"normalButton2"} variant="contained" endIcon={<AddIcon />}>
              Créer
            </Button>

    
          
          
        </CardContent>
        </Card>
        <br/>
        <Card className="searchCard" variant="outlined">

<CardContent>

<p  className="cornerText">Filtres </p>
<Grid container direction="row" justifyContent="space-around" alignItems="baseline">

   
      <Grid item>
      <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
      Type Produit :
    </Typography>
    <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">TypeProduit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Produit"
                  value={typeValue}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{
                    setPage(0);
                    setTypeValue(event.target.value);
                  }} 
                >  
                   <MenuItem  value={"ALL"}>ALL</MenuItem>           
                   <MenuItem  value={"Pizza"}>Pizza</MenuItem>
                   <MenuItem  value={"Tacos"}>Tacos</MenuItem>
                   <MenuItem  value={"Burger"}>Burger</MenuItem>
                   <MenuItem  value={"Panini"}>Panini</MenuItem>
                   <MenuItem  value={"Pasticcio"}>Pasticcio</MenuItem>
                   <MenuItem  value={"Salad"}>Salad</MenuItem>
                   <MenuItem  value={"Juice"}>Juice</MenuItem>
                   <MenuItem  value={"WheyJuice"}>WheyJuice</MenuItem>
        
                </Select>
              </FormControl>
      </Grid>
    
  </Grid>
</CardContent>
</Card>
<br/>
<TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">ID</TableCell>
      <TableCell className="filtertitle" align="left">Name</TableCell>
      <TableCell className="filtertitle" align="left">Type</TableCell>
      <TableCell className="filtertitle" align="left">Protein</TableCell>
      <TableCell className="filtertitle" align="left">Carbs</TableCell>
      <TableCell className="filtertitle" align="left">Fat</TableCell>
      <TableCell className="filtertitle" align="left">Calories</TableCell>
      <TableCell className="filtertitle" align="left">Recipe</TableCell>
      <TableCell className="filtertitle" align="left">Cost</TableCell>
      <TableCell className="filtertitle" align="left">SellPrice</TableCell>
      <TableCell className="filtertitle" align="left">Activated</TableCell>
      <TableCell className="filtertitle" align="left">Image</TableCell>
      <TableCell className="filtertitle" align="left">Edit</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {mealList.slice(page*10,(page+1)*10)
.map(meal => (
<TableRow
 key={meal.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell align="left">{meal.id}</TableCell>
<TableCell align="left">{meal.name.en}<br/>{meal.name.fr}<br/>{meal.name.ar}</TableCell>
<TableCell  align="left">{meal.type}</TableCell>
<TableCell  align="left">{meal.protein} g</TableCell>
<TableCell  align="left">{meal.carbs} g</TableCell>
<TableCell  align="left">{meal.fat} g</TableCell>
<TableCell  align="left">{meal.calories} Kcal</TableCell>
<TableCell  align="left">
{meal.recipe.map(element =>(
                   <div key={element.id}>{element.mealId} : q({element.quantity}g), {element.totalProtein}p, {element.totalCarbs}c, {element.totalFat}f   </div>
                   )
                    )}
  {/* local 1 :{product.quantity?.l1}<br/>local 2 :{product.quantity?.l2}<br/>local 3 :{product.quantity?.l3} */}
  
  </TableCell>
<TableCell  align="left">{meal.cost}</TableCell>
<TableCell  align="left">{meal.sellPrice} DH</TableCell>
<TableCell align="left">
      {meal.activated ? <Online/> : <Offline/>}

        </TableCell>
<TableCell  align="left"><MealImage imageName={meal.image}/></TableCell>


<TableCell align="left">
  <EditIcon 
  onClick={() => { openUpdateModal(meal)}}
    className="editIcon2"/>
    </TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={mealList.length}
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
    </div>)
}
export default View;