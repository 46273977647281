//Mui , hooks imports
import { Button, Card, CardContent, FormControl,
   Grid, InputLabel, MenuItem, Select, Table, TableBody,
    TableCell, TableFooter, TableHead, TablePagination, TableRow, Typography,
    Paper,TableContainer,Divider,Container
  } from "@mui/material";
import React, { useEffect, useState } from "react";
import {useSelector, useDispatch } from "react-redux";


//Icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import UploadIcon from '@mui/icons-material/Upload';


//Css
import "./achat.css";

//Axios Calls
import { getEndPoint, getInvoiceImageURL, getPurchasePaymentImageURL, saveLogoutInLocalStorage , getCompressedImage} from "../../helpers/constants";
import axios from "axios";
import { logout } from "../../redux/auth/authactions";

//Modals
import UpdateModal from "./components/UpdatedModal";
import SupplierOrderModal from "./components/UpdateSupplierOrderModal"
import ProductSearchModal from "./components/ProductSearchModal"

//My Costum inputs
import MyInput from "../../globalComponents/MyInput";
import SmallInput from "../../globalComponents/SmallInput";

//Others 
import { useNavigate } from "react-router-dom";
import TableSelection from "./components/TableSelection";
import InvoiceImageModal from "./components/InvoiceImageModal";


const View= () => {
   //Agent Identification
   const decodedJWT=useSelector(state => state.decodedJWT );
  //Locals
  const [locals,setLocals]=useState([]);
    //Supplier Table 
   const [isSupplierTable,setisSupplierTable]=useState(true);
   const [suppliers,setSuppliers]=useState([]);
   const [page, setPage] = useState(0);
   const [search,setSearch]= useState("");
   const handleChangePage = (event,newPage) => 
    {
        setPage(newPage);
    };
    const changeDisplayedTable=() =>{
      setisSupplierTable((old) => !old);
    }
    //SupplierOrder edit modal
    const [orderModalIsOpen,setOrderModalIsOpen]=useState(false);
    const [selectedOrder,setSelectedOrder]=useState({});
    const closeOrderModal=()=>{
      setOrderModalIsOpen(old => false);
      
    }
    const openOrderModal=(order) =>{
      setSelectedOrder(order);
      setOrderModalIsOpen(true);
    }
    const openInvoiceModal= async(image) =>{
      const endpoint=getInvoiceImageURL(image);
      setInvoiceImage(endpoint);
      setInvoiceModalIsOpen(true);
  

      

    }
    const handleOrderUpdate= async ({idOrder,data})=>{
 
     const myData=new FormData();
     myData.append("id",idOrder);
     myData.append("montant",data.montant);
     myData.append("type",data.type);
     myData.append("file",data.file);
     myData.append("factured",data.factured);
     
     if((data.montant==null || data.montant=="") || ((parseFloat(data.totalPaid)+parseFloat(data.montant))<=parseFloat(data.total)))
     {
      const endpoint=getEndPoint("/supplierOrders");
      const response = await axios.put(endpoint,myData,headers).catch(function (error) {
      if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
     
       freeTokenFromSessionStorage();
          
     }
 
    
    });
 
      await loadSupplierOrders();
        
 
       closeOrderModal();

     }
    
      
        
    }
    //Table Invoice Modal
    const [invoiceModalIsOpen,setInvoiceModalIsOpen]=useState(false);
    const [invoiceImage,setInvoiceImage]=useState("");
    const closeInvoiceModal=()=>{
      setInvoiceModalIsOpen(old => false);
    }
    //Table edit modal
    const [modalIsOpen,setmodalIsOpen]=useState(false);
    const [selectedSupplier,setSelectedSupplier]=useState({});
    const closeUpdateModal=()=>{
      setmodalIsOpen(olde => false);
      
    }
    const openUpdateModal=(supplier) =>{
      setSelectedSupplier(supplier);
      setmodalIsOpen(true);
    }

     const handleUpdate= async ({idSupplier,data})=>{

      
      const endpoint=getEndPoint("/suppliers");
      const response = await axios.put(endpoint+"?id="+idSupplier,data,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          freeTokenFromSessionStorage();
         
        }
   
   
      });
    
      await loadSuppliers();
       

      closeUpdateModal();
      
        
    }
    //Product Search Modal
 
    const [productInputIndex,setProductInputIndex]=useState(null);
    const [productModalIsOpen,setProductModalIsOpen]=useState(false);
    const closeProductModal=()=>{
      setProductModalIsOpen(old => false);
      
    }
    const openProductModal=(index) =>{
      setProductModalIsOpen(old => true);
      setProductInputIndex(index);
    }

     const handleProductModalSubmit= async ({indexProduct,productID,product})=>{
      setBoughtProducts((old) => {
        let mylist=[...old];
         
        mylist[indexProduct].productId=productID;
        mylist[indexProduct].found=true;
        mylist[indexProduct].buy_price=product.buyPricePer100g;
        if( mylist[indexProduct].quantity!="")
        mylist[indexProduct].total=(mylist[indexProduct].buy_price/100)*(mylist[indexProduct].quantity*mylist[indexProduct].unit);
        else
        mylist[indexProduct].total=null;
       
        calculateTotal(mylist);
        return mylist;

      });

      //closeUpdateModal();
      closeProductModal();
      
        
    }

    //Supplier
    const [id,setId] = useState("");  
    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");
    const [address,setAddress] = useState("");

    //Achat 
    const [supplierOrders,setSupplierOrders]=useState([]);
    const [idFournisseurAchat,setIdFournisseurAchat]=useState("");
    const [idLocalAchat,setIdLocalAchat]=useState("");
    const [boughtProducts,setBoughtProducts]=useState(
      [
      {
        found:null,
        index:0,
        productId:"",
        quantity:"",
        buy_price:null,
        total:null,
        unit:1000,
      }
    ]);
    const [productIndex,setProductIndex]=useState(1);
    //Order Information
    const [totalPayment,setTotalPayment]=useState(null);
    const [factureOrBonImage,setFactureOrBonImage]=useState(null);
    const [paymentsList,setPaymentsList]=useState(
      [
      {
        index:0,
        amount:"",
        type:"virement",
        image:null,
      }
    ]);
    const [paymentIndex,setPaymentIndex]=useState(1);

    // JWT and  freesession
    const history=useNavigate();
    const jwt=useSelector(state => state.jwt);
    const dispatch=useDispatch();
    const headers = { headers: {
        "Authorization" : `Bearer ${jwt}`,
      
  
      } }
    const freeTokenFromSessionStorage = () => {
        dispatch(logout);
        saveLogoutInLocalStorage();
        history("/login");
      };

    //Methods

    const debugfilling = () => {
        setId("AA892186");
        setName("Hamza Supplier");
        setAddress("Agdal Okba")
        setPhone("+212682767885");
        
  
      };
      const debugfillingAchat = () => {
        setIdFournisseurAchat("AA892186");
     

        
  
      };
    //Achat Methods 
    const setBoughtProduct=async ({index,value}) =>{
      const endpoint=getEndPoint("/product?productid="+value);
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
      
          freeTokenFromSessionStorage();
         
        }
   
      });
      const found = (data!=null);
      setBoughtProducts((old) => {
        let mylist=[...old];
         
        mylist[index].productId=value;
        mylist[index].found=found;
        if(found)
        {
          mylist[index].buy_price=data.data.buy_price;
          if( mylist[index].quantity!="")
          mylist[index].total=mylist[index].buy_price*mylist[index].quantity;
          else
          mylist[index].total=null;
        }
        else
        mylist[index].total=null;
        calculateTotal(mylist);
        return mylist;

      });
  
  

    }
    const setBoughtProductUnit=({index,value}) => {
      setBoughtProducts((old) => {
        let mylist=[...old];
        mylist[index].unit=value;
        if(mylist[index].found)
        {
          mylist[index].total=(mylist[index].buy_price/100)*(mylist[index].quantity*value);
        }
        else
        mylist[index].total=null;
        calculateTotal(mylist);
        return mylist;

      });
    }
    const setBoughtProductQuantity=({index,value})=>{
      setBoughtProducts((old) => {
        let mylist=[...old];
        mylist[index].quantity=value;
        if(mylist[index].found)
        {
          mylist[index].total=(mylist[index].buy_price/100)*(mylist[index].quantity*mylist[index].unit);
        }
        else
        mylist[index].total=null;
        calculateTotal(mylist);
        return mylist;

      });
      

    }
    const addProduct= ()=>{
      
      setBoughtProducts( old =>
       {
         let mylist=[...old];
    
         mylist.push(
          {          
            found:null,
            index:productIndex,
            productId:"",
            quantity:"",
            buy_price:null,
            total:null,
            unit:1000,
          }
         );
      
         calculateTotal(mylist);
       return mylist;

      }
      );
      setProductIndex(old => old+1);

      
    };
    const removeProduct=()=>{
      if(productIndex>1)
      {
      setBoughtProducts(
        (old) =>{
          let mylist=[...old];
          mylist.pop();
          calculateTotal(mylist);
          return mylist;
        }
      );
      setProductIndex(old => old-1);
      }

    };
    //Payment Infos
    const calculateTotal=(list) =>
    {
      let total=0;
    
      for (var i = 0; i < list.length; i++) {
        if(list[i].total==null || isNaN(list[i].total) )
        {
          total=null;
          break;
        }
        else
          total+=list[i].total;
      }
      setTotalPayment(old => total);
      return total;

    }
    const calculateTotalPayment=() => {
      let total=0;
      paymentsList.map(payment=> {
        total+=parseFloat(payment.amount);
      });
      return total;
    }
    const setPaymentAmount=({index,value})=>{
      setPaymentsList((old) => {
        let mylist=[...old];
        mylist[index].amount=value;
        return mylist;

      });

    }
    const setPaymentType=({index,value})=>{
      setPaymentsList((old) => {
        let mylist=[...old];
        mylist[index].type=value;
        return mylist;

      });
    }
    const setPaymentImage=({index,value})=>{
  
      setPaymentsList((old) => {
        let mylist=[...old];
        mylist[index].image=value;
        return mylist;

      });

    }
 

    const addPayment= () => {
      setPaymentsList(old =>
        {
          let mylist=[...old];
          mylist.push(
            {
              index:paymentIndex,
              amount:"",
              type:"virement",
              image:null,
            }
          );
        return mylist;

        });
        setPaymentIndex(old => old+1);
    

    }
    const removePayment=()=>{
      if(paymentIndex>1)
      {
        setPaymentsList(
        (old) =>{
          let mylist=[...old];
          mylist.pop();
          return mylist;
        }
      );
      setPaymentIndex(old => old-1);
      }
 
    };

    //Axios Calls 
    const [stock,setStock]=useState([]);
    const loadStock=async()=>{
      const endpoint=getEndPoint("/foodList");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
      
          freeTokenFromSessionStorage();
         
        }
   
      });
      setStock(old => data.data);

  };
    const loadSupplierOrders=async()=>{
      const endpoint=getEndPoint("/supplierOrders");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          freeTokenFromSessionStorage();
         
        }
   
      });
      setSupplierOrders(old => data.data);
    }
    const loadSuppliers= async() => {
      const endpoint=getEndPoint("/suppliers");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          freeTokenFromSessionStorage();
         
        }
   
      });
      setSuppliers(old => data.data);

    }
    const createSupplier= async() => {
        const data={
            id:id,
            name:name,
            phone:phone,
            address:address,
          }
          
          const endpoint=getEndPoint("/suppliers");
          const response = await axios.post(endpoint,data,headers).catch(function (error) {
            if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
              freeTokenFromSessionStorage();
             
            }
         
       
          });
         // Load Suppliers for table await loadUsers();
         if(response!=null)
         {
          clearSupplierInput();
          loadSuppliers();
         }
        
     
         
    };
    const clearSupplierInput=()=>{
      setId("");
      setName("");
      setPhone("");
      setAddress("");
  

    }


    const createBuyOrder= async()=>{
     if(totalPayment!=null && (calculateTotalPayment()<=totalPayment))
     {
      
      let formData=new FormData();
      let data={
         supplierid:null,
         localId:null,
         agentId:null,
          total:null,
         paidAmount:null,
         status:null,
         factured:false,
      
        purchasedProducts:[],
        purchasePayments:[]
      };
      data.supplierid=idFournisseurAchat;
 
      data.localId=idLocalAchat;

      data.total=totalPayment;
  
    
      boughtProducts.map((product)=>{

       
        data.purchasedProducts.push(
          {
          productId:product.productId,
          quantity:product.quantity*product.unit,
          buy_price:product.buyPricePer100g,
          total:product.total,
          }
        );

      });
      let paidamountVariable=0;

      paymentsList.map(async(payment)=> {
        var compressedImage=await getCompressedImage(payment.image);


        formData.append("files",compressedImage);
   
        paidamountVariable+=parseFloat(payment.amount);

        data.purchasePayments.push(payment);
        
      });
      data.paidAmount=paidamountVariable;

      if(paidamountVariable<data.total)
      {
      data.status="En Cours";

      }
      else
      {
      data.status="Complet";
      }
     data.purchasePayments.map((purchasepayment)=>
           {
        if(purchasepayment.image==null)
        purchasepayment.image="";
        else
        purchasepayment.image="Found";
      } 
        );
      formData.append("givendata",JSON.stringify(data));

      var compressedImage=await getCompressedImage(factureOrBonImage);
      formData.append("factureBonImage",compressedImage);

   const endpoint=getEndPoint("/PurchaseFood");
      const response = await axios.post(endpoint,formData,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {

          freeTokenFromSessionStorage();
         
        }
   
      });
      if(response!=null)
      clearBuyOrder();
      else
      clearInputFiles();
      loadSupplierOrders();

    }

    }
    const clearInputFiles=()=>{
      paymentsList.map((payment)=>{
        payment.image=null;
        
      });

    }
    const clearBuyOrder=() => {
      setIdFournisseurAchat("");


      setBoughtProducts([
        {
          found:null,
          index:0,
          productId:"",
          quantity:"",
          buy_price:null,
          total:null,
        }
      ]);
      setProductIndex(1);
      setTotalPayment(null);
      setPaymentsList([
        {
          index:0,
          amount:"",
          type:"virement",
          image:null,
        }
      ]);
      setPaymentIndex(1);
      setFactureOrBonImage(null);
    }
    const uploadFactureBon= async({orderId,image}) => {
      let formData=new FormData();
      formData.append("orderId",orderId);
      formData.append("image",image);
      const endpoint=getEndPoint("/uploadFactureBon");
      const response = await axios.post(endpoint,formData,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          freeTokenFromSessionStorage();
         
        }
   
      });
      loadSupplierOrders();


    }
    //Loads 
    const loadLocals= async() => {
      const endpoint=getEndPoint("/Local");
      const data= await axios.get(endpoint,headers).catch(function (error) {
        if (error.response && (error.response.status=="401" ||  error.response.status=="403")) {
          freeTokenFromSessionStorage();
         
        }
   
      });
      setLocals(old => data.data);

    }
    useEffect(()=>{
      loadStock();
      loadSuppliers();
      loadSupplierOrders();
      loadLocals();
    },[]);
    useEffect(()=>{
      console.log("Decoded JWT :");
      console.log(decodedJWT);
    //  if(decodedJWT.Role!="SuperAdmin")
      setIdLocalAchat(old => decodedJWT.localId);
      console.log("DECODED JWT  use effect part :")
      console.log(decodedJWT);
      console.log("local Id:"+decodedJWT.localId);
   
     },[decodedJWT]);
    return ( <div>
<ProductSearchModal open={productModalIsOpen} index={productInputIndex} locals={locals} products={stock} handleClose={closeProductModal} handleSubmit={handleProductModalSubmit}  />

        <UpdateModal  open={modalIsOpen} supplier={selectedSupplier} handleClose={closeUpdateModal} handleUpdate={handleUpdate} locals={locals}  />
        <InvoiceImageModal  open={invoiceModalIsOpen} img={invoiceImage} handleClose={closeInvoiceModal}   />
      
        <SupplierOrderModal  open={orderModalIsOpen} supplierOrder={selectedOrder} handleClose={closeOrderModal} handleUpdate={handleOrderUpdate}  />
        <Grid container spacing={1}>
            <Grid item md={6}>
            <Card className="creationCard" variant="outlined">
       
<CardContent >
          <p onClick={debugfilling} className="cornerText">Nouveau Fournisseur </p>
      
          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
          <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             ID : 
            </Typography>
            <MyInput value={id} onChange={(event) => setId(event.target.value)} 
            placeholder="ID" 
    />
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              NomPrenom :
            </Typography>
            <MyInput value={name} onChange={(event) => setName(event.target.value)} 
            placeholder="NomPrenom" 
      />
              </Grid>
              </Grid>
     
              <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Numéro de téléphone :
            </Typography>
            <MyInput value={phone} onChange={(event) =>{setPhone(event.target.value)}} 
            placeholder="Numéro de téléphone" 
         />
              </Grid>
  
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Adresse :
            </Typography>
            <MyInput value={address} onChange={(event) =>{setAddress(event.target.value)}} 
            placeholder="Adresse" 
        />
              </Grid>
              </Grid>
              <br/>
           

    
       
          <Button      style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }} onClick={createSupplier} className={"normalButton2"} variant="contained" endIcon={<AddIcon />}>
              Créer
            </Button> 
        
        </CardContent>
      
    
        </Card>   
 
  
            </Grid>
            <Grid item md={6}>
            <Card className="creationCard" variant="outlined">
<CardContent>
          <p onClick={debugfillingAchat} className="cornerText">Achat Produit</p>

          <Grid container direction="row" justifyContent="space-around" alignItems="baseline">
      
             <Grid item>

             <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
             ID Fournisseur :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Fournisseur</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="ID Fournisseur"
                  value={idFournisseurAchat}
                  className="selectStyle"
                  onChange={(event) => setIdFournisseurAchat(event.target.value)}
                >
                 {suppliers.map(supplier => (
                   <MenuItem key={supplier.id} value={supplier.id}>{supplier.id}</MenuItem>
                 ))}
                </Select>
              </FormControl>
             </Grid>
             {decodedJWT.Role=="SuperAdmin" ?
             <Grid item>

             <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Local :
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">ID Local</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="LocalID"
                  className="selectStyle"
                  onChange={(event)=> {setIdLocalAchat((old)=> (event.target.value))}}
                >
             {locals.map(local =>(
                    <MenuItem key={local.id} value={local.id}>{local.id}</MenuItem>
                  )
                    )}
           
                </Select>
              </FormControl>
             </Grid>
              :
              null
             }
          </Grid>
        
          <Divider  style={{"margin-right":"auto","margin-left":"auto","width":"20rem","margin-bottom":"5px","margin-top":"10px"}} orientation="horizontal" variant="middle"/>
          <Grid container direction="row" justifyContent="center" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Produits 
            </Typography>
            </Grid>
            </Grid>
      
          {boughtProducts.map((product)=>
            <Grid key={product.index} container direction="row" justifyContent="space-around" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                ID Produit :
              </Typography>

    <span style={{"display":"flex"}}>
<MyInput found={(product.productId==="")? null : product.found}  value={product.productId} onChange={(event) =>  setBoughtProduct({index:product.index,value:event.target.value})} 
              placeholder="ID Produit" />
               <span style={{"width":"2px"}}/>
              <Button      style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }}  variant="contained" onClick={() => openProductModal(product.index)} ><SearchIcon style={{"fontSize":"28px"}}/></Button>
              </span>
              {product.total!==null &&
                  <Grid container direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
                  {isNaN(product.total)?
                   <Typography className="errorlittleTitle" sx={{ fontSize: 14 }} gutterBottom>
                   Quantité invalide !
                   </Typography>
                  :
                  <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                    Total : {product.total} DH
                  </Typography>
                  }
                  
                  </Grid>
                  </Grid>
           
                  
               }
            </Grid>
           
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
               Quantity : 
              </Typography>
              <MyInput value={product.quantity} onChange={(event) => setBoughtProductQuantity({index:product.index,value:event.target.value})} 
              placeholder="Quantity (Kg/Unit)" 
             />
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
               Unit : 
              </Typography>
              <FormControl >
                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Paiement"
                  className="smallSelectStyle"
                   value={product.unit}
                  onChange={(event)=> { setBoughtProductUnit({index:product.index,value:event.target.value})}}
                >
                 <MenuItem value={1000}>Kilo</MenuItem>
                    <MenuItem value={1} >Unit</MenuItem>
                
           
                </Select>
                    </FormControl>
            </Grid>
        
            </Grid>
          )}
          
          <br/>
            <Grid  container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid  item>
                   <AddIcon onClick={addProduct} fontSize="large" className="addProductIconContainer"/>
              </Grid>
              <Grid  item>
                   <RemoveIcon onClick={removeProduct} fontSize="large" className="removeProductIconContainer" />
              </Grid>
            </Grid>
            <Divider  style={{"margin-right":"auto","margin-left":"auto","width":"20rem","margin-bottom":"5px","margin-top":"10px"}} orientation="horizontal" variant="middle"/>
            <Grid container direction="row" justifyContent="center" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Informations de commande 
            </Typography>
            </Grid>
            </Grid>
            {totalPayment!==null && 
              <Container className="orderInfoContainer" >
              <Grid container  spacing={0}>
                <Grid item md={3} xs={3}>
                <Typography className="littleTitle" sx={{ fontSize: 14 }} color={"white"} gutterBottom>
                  Total :
                </Typography>
                </Grid>
                <Grid item md={9} xs={9}>
                  <Grid container  direction="row" justifyContent="center" alignItems="baseline">
                    <Grid item>
                <Typography className="bigTitle" sx={{ fontSize: 14 }} color={"white"} gutterBottom>
                 {totalPayment} DH
                </Typography>
                </Grid>
                </Grid>

                </Grid>
                <Grid item md={3} xs={3}>
                <Typography className="littleTitle" color={"white"} sx={{ fontSize: 14 }} gutterBottom>
                  Facture/Bon  :
                </Typography>
                </Grid>
                <Grid item md={9} xs={9}>
                  <Grid container  direction="row" justifyContent="center" alignItems="baseline">
                    <Grid item>
                    <Button
                         style={{
                          "background-color": "#212121",
                          "color":"#FFFFFF"
                  
                       }}
                        variant="contained"
                        component="label" 
                         className={factureOrBonImage!==null? "insertPhotoButton":""    }
                      
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                         onChange={(event) => setFactureOrBonImage(old => event.target.files[0])}
                        />
                      </Button>
              
                </Grid>
                </Grid>

                </Grid>
                <Grid md={12} xs={12} item>
                <br/>
                </Grid>
                
                <Grid item md={3} xs={3}>
                <Typography className="littleTitle" color={"white"} sx={{ fontSize: 14 }} gutterBottom>
                  Paiements :
                </Typography>
                </Grid>
                <Grid item md={9} xs={9}>
             
                {paymentsList.map((payment) =>(
                  <Grid style={{"paddingBottom":"5px"}} key={payment.index} container spacing={1}  direction="row" justifyContent="center" alignItems="baseline">
                  <Grid item>
                   <SmallInput found={(payment.amount==="" || !isNaN(payment.amount) )? 
                                         null
                                         :
                                         false} value={payment.amount} onChange={(event) =>{setPaymentAmount({index:payment.index,value:event.target.value})}} 
                          placeholder="Montant" 
                   />
                <span >  </span>
                         <FormControl >
                <InputLabel id="demo-simple-select-label">Type Paiement</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Type Paiement"
                  className="smallSelectStyle"
                  value={payment.type}
                  onChange={(event)=> {setPaymentType({index:payment.index,value:event.target.value})}}
                >
                 <MenuItem value="virement">virement</MenuItem>
                    <MenuItem value="transfert">transfert</MenuItem>
                    <MenuItem value="espece">espece</MenuItem>
                    <MenuItem value="cheque">cheque</MenuItem>
           
                </Select>
                    </FormControl>
                    <span >  </span>
                    <Button
                        style={{
                          "background-color": "#212121",
                          "color":"#FFFFFF"
                  
                       }}
                        variant="contained"
                        component="label" 
                        className={payment.image!==null? "insertPhotoButton":""    }
                      >
                        <CameraAltIcon/>
                        <input
                        accept="image/*"
                          type="file"
                          hidden
                          onChange={(event) => setPaymentImage({index:payment.index,value:event.target.files[0]})}
                        />
                      </Button>
                   </Grid>
                </Grid>
    ))}
    <br/>
    
                <br/>
                
                <Grid  container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid  item>
                   <AddIcon onClick={addPayment} fontSize="small" className="addPaymentIconContainer"/>
              </Grid>
              <Grid  item>
                   <RemoveIcon onClick={removePayment} fontSize="small" className="removePaymentIconContainer" />
              </Grid>
            </Grid>

                </Grid>

              </Grid>
              
            </Container>
            }
          
             


             <br/>
             <br/>
              <Button      style={{
        "background-color": "#212121",
        "color":"#FFFFFF"

     }} onClick={createBuyOrder} className={"normalButton2"} variant="contained" endIcon={<AddIcon />}>
              Valider
            </Button>      
        </CardContent>
        </Card>   
            </Grid>
        </Grid>
        <br/>
{/* Search Part */}
<Card className="searchCard" variant="outlined">
<TableSelection  changeDisplayedTable={changeDisplayedTable} isSupplierTable={isSupplierTable}/>
        <CardContent>
    
        <p  className="cornerText">Recherche Global  </p>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Recherche :
            </Typography>
            <MyInput value={search} onChange={(event) =>{setSearch((old)=> (event.target.value))}} 
            placeholder="Recherche" 
      />
              </Grid>
          </Grid>
      
        </CardContent>
      
    </Card>


    <br/>
{/* Table Part */}
{isSupplierTable ? 
  <TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">id Fournisseur</TableCell>
      <TableCell className="filtertitle" align="left">NomPrenom</TableCell>
      <TableCell className="filtertitle" align="left">Numéro de téléphone</TableCell>
      <TableCell className="filtertitle" align="left">Adresse</TableCell>
      <TableCell className="filtertitle" align="left">Modifier</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {suppliers.filter(supplier => (
  supplier.id?.includes(search) || 
  supplier.name?.includes(search) || 
  supplier.phone?.includes(search) ||
  supplier.address?.includes(search) 

  ))
.slice(page * 10, page * 10 + 10)
.map(supplier => (
<TableRow
// key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{supplier.id}</TableCell>
<TableCell align="left">{supplier.name}</TableCell>
<TableCell  align="left">{supplier.phone}</TableCell>
<TableCell align="left">{supplier.address}</TableCell>
<TableCell align="left"><EditIcon onClick={() => {openUpdateModal(supplier)}}  className="editIcon2"/></TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            suppliers.filter(supplier => (
              supplier.id?.includes(search) || 
              supplier.name?.includes(search) || 
              supplier.phone?.includes(search) ||
              supplier.address?.includes(search) 
            
              )).length
            }
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
:
<TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">Date</TableCell>
      <TableCell className="filtertitle" align="left">Id Fournisseur</TableCell>
      <TableCell className="filtertitle" align="left">Id Local</TableCell>
      <TableCell className="filtertitle" align="left">Produits Acheté</TableCell>
      <TableCell className="filtertitle" align="left">Paiements</TableCell>
      <TableCell className="filtertitle" align="left">est Facturé</TableCell>
      <TableCell className="filtertitle" align="left">Statut</TableCell>
      <TableCell className="filtertitle" align="left">Montant Payé</TableCell>
      <TableCell className="filtertitle" align="left">Montant Total</TableCell>
      <TableCell className="filtertitle" align="left">Facture/Bon</TableCell>
      <TableCell className="filtertitle" align="left">Edit</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
  {supplierOrders.filter(order => (
  order.supplierid?.includes(search) || 
  order.localId?.includes(search) || 
  (order.total.toString())?.includes(search) || 
  (order.paidAmount.toString())?.includes(search) || 
  order.created_At?.includes(search)
  ))
.slice(page * 10, page * 10 + 10)
.map(order => (
<TableRow
// key={order.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row" align="left">{order.created_At}</TableCell>
<TableCell align="left">{order.supplierid}</TableCell>
<TableCell  align="left">{order.localId}</TableCell>
<TableCell align="left">{order.purchasedProducts.map((product)=>(
    <div key={product.productId}>
      {product.quantity}x {product.productId},Total:{product.total}<br/>

    </div>
))}</TableCell>
<TableCell align="left">{order.purchasePayments.map((paiement)=>(
    <div key={paiement.index}>
      <span className="blueText">Montant :</span>{paiement.amount} DH, <span className="blueText">Type :</span>{paiement.type}, {(paiement.image!=null && paiement.image!=="" )&& <a href={getPurchasePaymentImageURL(paiement.image)}  target="_blank" rel="noreferrer" >Image</a> }<br/>

    </div>
))}</TableCell>
{/* getEndPoint("images")+"/"+paiement.image */}
<TableCell align="left">{order.factured? <span>Facturé</span> :<span>Non Facturé</span> }</TableCell>
<TableCell align="left">{order.status}</TableCell>
<TableCell align="left">{order.paidAmount} DH</TableCell>
<TableCell align="left">{order.total} DH</TableCell>
<TableCell align="left">
  {order.factureBonImage!=null? 
  <Button      style={{
    "background-color": "#212121",
    "color":"#FFFFFF"

 }} className={"invoiceButtonDone"} onClick={() => openInvoiceModal(order.factureBonImage)} ><SearchIcon /></Button>
   :
   <Button 
   variant="contained"
   component="label" 
   style={{
    "background-color": "#212121",
    "color":"#FFFFFF"

 }} 
   className={"invoiceButton"}>
    <UploadIcon/>
    <input
                        accept="image/*"
                          type="file"
                          hidden
                          onChange={(event) => uploadFactureBon({orderId:order.id,image:event.target.files[0]})}
                        />
    </Button>
   }</TableCell>

<TableCell align="left"><EditIcon  className="editIcon2" onClick={() => openOrderModal(order)}/></TableCell>

</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            supplierOrders.filter(order => (
              order.supplierid?.includes(search) || 
              order.localId?.includes(search) || 
              (order.total.toString())?.includes(search) || 
              (order.paidAmount.toString())?.includes(search) || 
              order.created_At?.includes(search)
              )).length
            }
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>
</TableContainer>
}
    </div>);
 };
 export default View;