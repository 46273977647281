import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import {Provider}  from "react-redux";
import Store from "./redux/store";


createRoot(document.querySelector("#root")).render(
  <Provider store={Store}> 
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Provider>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
