import {Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Button,Slide,
  Card,CardContent, Grid ,Typography,TableContainer,Table
  ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
  Paper,Select,MenuItem,FormControl,InputLabel } from "@mui/material";
import InputUnstyled from '@mui/base/InputUnstyled';
import React, { useState,useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import {saveLogoutInLocalStorage,generateArrayOfMonths,generateArrayOfYears,generateArrayOfYearsForward,generateDays} from "../../../../helpers/constants";
import { styled } from '@mui/system';

//Icons
import SearchIcon from '@mui/icons-material/Search';

//My Components 
import UserSearchModal from "../UserSearchModal";

//My Costum inputs
import MyInput from "../../../../globalComponents/MyInput";
import SmallInput from "../../../../globalComponents/SmallInput";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const UpdateModal=({open,handleClose,promoCode,handleUpdate,usersList}) => {

   //User Modal
   const [userIdFound,setUserIdFound]=useState(null);
   const [userModalIsOpen,setUserModalIsOpen]=useState(false);
   const closeUserModal=()=>{
     setUserModalIsOpen(old => false);
     
   }
   const openUsersModal=(index) =>{
    setUserModalIsOpen(old => true);
    //  setProductInputIndex(index);
   }

   const handleUserSearchModalSubmit= async ({userId})=>{
   
    setUserId(old => userId);
    setUserIdFound(old => true);

  closeUserModal();
  
    
}

   //Helper Methods
   const getDiscountValues=() => {
    let values=[]
    for(let i=0;i<=30;i++)
    {
     values.push(<MenuItem  value={i}>{i} %</MenuItem>)
    }
    return values;
  }


   //Rest of code

  const [id,setId] = useState("");
  const [userId,setUserId] = useState("");
  const [ownerShare,setOwnerShare] = useState("");
  const [userDiscount,setUserDiscount] = useState("");
  const [endDate,setEndDate] = useState({
    day:0,
    month:0,
    year:0,
  });
  const [enabled,setEnabled] = useState(false);
  const [myUsersList,setMyUsersList]=useState([]);
  
 

 useEffect(
   () => {
    const date=new Date(promoCode.endDate);

    setId(promoCode.id);
    setUserId(promoCode.userId);
    setOwnerShare(promoCode.ownerShare);
    setUserDiscount(promoCode.userDiscount);
    if(promoCode.endDate!=null)
    setEndDate(
      {
        day:date.getDate(),
        month:date.getMonth()+1,
        year:date.getFullYear(),
      }
    );
    setEnabled(promoCode.enabled);
    setMyUsersList(old => usersList);

   },
   [promoCode]
 );


  const setYear=(value) =>
    {
      setEndDate((old) => {
            return {
              ...old,
              year:value,
            }
      });

    };
    const setMonth=(value) =>
    {
      setEndDate((old) => {
            return {
              ...old,
              month:value,
            }
      });

    };
    const setDay=(value) =>
    {
      setEndDate((old) => {
            return {
              ...old,
              day:value,
            }
      });

    };
    const mydateToString=()=>{
      return endDate.year+"-"+endDate.month+"-"+endDate.day;

    }
 
  
    const updatedData=() => {

      return {
      
         id:id,
         userId:userId,
         ownerShare:ownerShare,
         userDiscount:userDiscount,
         endDate: new Date(mydateToString()),
         enabled:enabled,
   
        

      }
    }
 
  // const freeTokenFromSessionStorage = () => {
  //   dispatch(logout);
  //   saveLogoutInLocalStorage();
  //   history("/login");
  // };
    return (
      <div>
         <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
           <UserSearchModal open={userModalIsOpen}   users={usersList} handleClose={closeUserModal} handleSubmit={handleUserSearchModalSubmit}  />
    
        <DialogTitle className="cornerText"> Modify PromoCode : {promoCode.id} </DialogTitle>
        <DialogContent id="dialogContent" >
          <Grid style={{padding:10}} container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                ID User :
              </Typography>

    <span style={{"display":"flex"}}>
<MyInput found={userIdFound}  value={userId} placeholder="ID User" />
               <span style={{"width":"2px"}}/>
              <Button       style={{
        "background-color": "#2ec4b6",
        "color":"#FFFFFF"

     }} variant="contained" onClick={() => openUsersModal()} ><SearchIcon style={{"fontSize":"28px"}}/></Button>
              </span>
          
            </Grid>
              <Grid item xs={12} md={6} >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Owner Share :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Owner Share</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Owner Share"
                  value={ownerShare}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setOwnerShare(event.target.value)}} 
                >      
  
             
                   {getDiscountValues()}
             
                </Select>
              </FormControl>
          
              </Grid>
              <Grid item xs={12} md={6} >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              User Discount :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">User Discount</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="User Discount"
                  value={userDiscount}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setUserDiscount(event.target.value)}} 
                >      
  
             
                   {getDiscountValues()}
             
                </Select>
              </FormControl>
          
              </Grid>
           
              <Grid item xs={12} md={6}>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              End Date :
            </Typography>
            <Grid style={{width:345}} container spacing={1}>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Année</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Année"
                  displayEmpty
                  value={endDate.year}
                  className="birthdayselectStyle"
                  onChange={(event)=> setYear(event.target.value)}
                >
                  {generateArrayOfYearsForward().map(year => (
            
                    <MenuItem key={year} value={year}>{year}</MenuItem>

                  ))}
                 
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Mois</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Mois"
                  displayEmpty
                  value={endDate.month}
                  className="birthdayselectStyle"
                
                  onChange={(event)=> setMonth(event.target.value)}
                >
                  {generateArrayOfMonths().map((month) => (
        
                    <MenuItem key={month.number} value={month.number}>{month.name}</MenuItem>

                  ))}
                </Select>
              </FormControl>
              </Grid>
              <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Jour</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Jour"
                  className="birthdayselectStyle"
                  displayEmpty
                  value={endDate.day}
                  onChange={(event)=> setDay(event.target.value)}
                >
                  {generateDays().map((daynNumber) => (
        
        <MenuItem key={daynNumber} value={daynNumber}>{daynNumber}</MenuItem>

      ))}
                </Select>
              </FormControl>
              </Grid>
            </Grid>
       
              </Grid>
              <Grid item >
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Enabled :
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Enabled</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="User Discount"
                  value={enabled}
                  className="selectStyleNewProduct"
                  onChange={(event) =>{setEnabled(event.target.value)}} 
                >      
                <MenuItem  value={true}>True</MenuItem>
                <MenuItem  value={false}>False</MenuItem>
             
                </Select>
              </FormControl>
          
              </Grid>
          
                    

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
          <Button id="modifierButton" onClick={
           () => {
            handleUpdate({

              data:updatedData(),
            });
           }
            }>Modifier</Button>
        </DialogActions>
      </Dialog>
      </div>
    )
};
const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  600: '#0072E5',
};
const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};
const StyledInputElement = styled('input')(
  ({ theme }) => `
  width: 320px;
  height:38px;
  font-size: 1rem;
 
  font-weight:  500;
  line-height: 1.5;
  color: #3b3b3b;
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 5px;
  padding: 8px 8px;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }
`,
);
export default UpdateModal;
