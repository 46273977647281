import {Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Button,Slide,
    Card,CardContent, Grid ,Typography,TableContainer,Table
    ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
    Paper,Select,MenuItem,FormControl,InputLabel,TextareaAutosize } from "@mui/material";
  import InputUnstyled from '@mui/base/InputUnstyled';
  import React, { useState,useEffect } from "react";
  import "./index.css";
  import { useDispatch, useSelector } from "react-redux";
  import {saveLogoutInLocalStorage,generateArrayOfMonths,generateArrayOfYears,generateDays} from "../../../../helpers/constants";
  import {logout} from "../../../../redux/auth/authactions";

  //Icons

  import AddIcon from '@mui/icons-material/Add';

import RemoveIcon from '@mui/icons-material/Remove';
  //My Costum inputs
import MyInput from "../../../../globalComponents/MyInput";


  const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  const DeliveryAreaModal=({open,handleClose,local,handleUpdate}) => {
    const [deliveryAreas,setDeliveryAreas] = useState([
      {
        index:0,
        name:"",
        values:""
      }
    ]);
  
   

    const setDeliveryAreaElementValues=({index,value})=>{
      setDeliveryAreas(
       (old) => {
           let myList=[...old];
           myList[index].values=value;
           return myList;
       }
      )

   }

    const setDeliveryAreaElementName=({index,value})=>{
       setDeliveryAreas(
        (old) => {
            let myList=[...old];
            myList[index].name=value;
            return myList;
        }
       )

    }

    //Add Remove 
    const [deliveryAreaIndex,setDeliveryAreaIndex]=useState(1);
    const addDeliveryArea= ()=>{
        
      setDeliveryAreas( old =>
       {
         let mylist=[...old];
    
         mylist.push(   
          {
          index:deliveryAreaIndex,
          name:"",
          values:""
        }
        );

       return mylist;
  
      }
      );
      setDeliveryAreaIndex(old => old+1);
  
      
    };
    const removeDeliveryArea=()=>{
      if(deliveryAreaIndex>1)
      {
        setDeliveryAreas(
        (old) =>{
          let mylist=[...old];
          mylist.pop();
          return mylist;
        }
      );
      setDeliveryAreaIndex(old => old-1);
      }
  
    };
   


   
   useEffect(
     () => {
      if(local.deliveryAreas!=null)
      {
        var data=local.deliveryAreas;
        var myData=[];
        for(var i=0;i<data.length;i++)
        {
          var valuesString="";
          for(var j=0;j<data[i].values.length;j++)
          {
            valuesString+=data[i].values[j]+",";
          }
          valuesString=valuesString.slice(0,-1);

          myData.push({
            index:i,
            name:data[i].name,
            values:valuesString,
          })

          

        }
        setDeliveryAreas(myData);

      }
      else
      {
        setDeliveryAreas(
          [
            {
              index:0,
              name:"",
              values:""
            }
          ]
        )
      }
     },
     [local]
   );


    const emptyInputs=()=>
    {
      setDeliveryAreas(
        [
          {
            index:0,
            name:"",
            values:""
          }
        ]
      );
      
      setDeliveryAreaIndex(1);

    }
   
     const deliveryAreasValid=() => {
      var everythingValid=true;
      for(var i=0;i<deliveryAreas.length;i++)
      {
        if(deliveryAreas[i].name=="")
        {
          everythingValid=false;
          break;
        }
        var myValues=deliveryAreas[i].values.split(",");

        var valuesAllDouble=true;
        for(var j=0;j<myValues.length;j++)
        {

          if(isNaN(myValues[j]))
          {
            
            valuesAllDouble=false;
            break;
          }

        }
        if(!valuesAllDouble)
        {
          everythingValid=false;
          break;
        }

        if(myValues.length%2!=0)
        {
          
          console.log("Values not pair ! for index :"+i);
          everythingValid=false;
          break;
        }
      }
      console.log("Everything IS valid :"+everythingValid);
      return everythingValid;
     }
     const generateDeliveryAreas=() => {
      if(deliveryAreasValid())
      {
        var data=[];
        for(var i=0;i<deliveryAreas.length;i++)
        {
          
          data.push(
            {
              name:deliveryAreas[i].name,
              values:deliveryAreas[i].values.split(","),
            }
          )
        }
        return data;
      

      }
      else
      {
        return null
      }

     }
    
      // const updatedData=() => {
      //   console.log("LocalServingPoints before:"+localServingPoints);
      //   var localServingPointsSplit= localServingPoints.split(",");
      //   console.log("LocalServingPoints after:"+localServingPointsSplit);

      //   var localServingPointsData=[];
      //   localServingPointsSplit.map(
      //     (localServingPoint) => {
      //       localServingPointsData.push(localServingPoint);
      //     }
      //   );
      //   const data={
      //     id:id,
      //     address:address,
      //     size:size,
      //     floors:floors,
      //     rent:rent,
      //     contract:contract,
      //     contract_info:contract_info,
      //     start_date:new Date(mydateToString()),
      //     localServingPoints:localServingPointsData
      //  }
  
      //  return data;
      // }

      return (
          <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="md"
        >
          <DialogTitle className="cornerText"> Modifier Local : {local?.id} </DialogTitle>
          <DialogContent id="dialogContent" >
          {deliveryAreas.map((deliveryArea)=>
            <Grid key={deliveryArea.index} container direction="column" justifyContent="space-around" alignItems="baseline">
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
                Delivery Area Name :
              </Typography>

              <MyInput value={deliveryArea.name} placeholder="Delivery Area Name" onChange={(event) => setDeliveryAreaElementName({index:deliveryArea.index,value:event.target.value})}  />
            </Grid>
           
            <Grid item>
            <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
               Delivery Area Values : 
              </Typography>
              <TextareaAutosize value={deliveryArea.values} style={{height:300,width:700}} onChange={(event) => setDeliveryAreaElementValues({index:deliveryArea.index,value:event.target.value})} />
         
            </Grid>
            </Grid>
          )}
          <br/>
            <Grid  container direction="row" justifyContent="space-around" alignItems="baseline">
              <Grid  item>
                   <AddIcon onClick={addDeliveryArea} fontSize="large" className="addProductIconContainer"/>
              </Grid>
              <Grid  item>
                   <RemoveIcon onClick={removeDeliveryArea} fontSize="large" className="removeProductIconContainer" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="fermerButton" onClick={() => {
          
              handleClose();
            }}>Fermer</Button>
            <Button id="modifierButton" onClick={
             () => {
              var data=generateDeliveryAreas();
              console.log("Generated DeliveryAreas");
              console.log(data);

              handleUpdate({
                idLocal:local.id,
                deliveryAreas:data,
              });
             }
              }>Modifier</Button>
          </DialogActions>
        </Dialog>
      )
  };


  export default DeliveryAreaModal;
  