import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Slide,
  Grid ,Typography,Select,MenuItem,FormControl,InputLabel, Table, TableContainer,
   TableRow, TableCell, TableBody, TableFooter, TablePagination,Paper,TableHead, Card, CardContent} from "@mui/material";

import React, { useState,useEffect } from "react";
import { FoodImage } from "../../../../globalComponents/MyImageContainers";
import MyInput from "../../../../globalComponents/MyInput";

import "./productSearchModal.css";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const UserSearchModal=({open,handleClose,users,handleSubmit}) => {

  const [page, setPage] = useState(0);
  const [search,setSearch]= useState("");
  const [myUsers,setMyUsers]=useState([]);

  const handleChangePage = (event,newPage) => 
   {
       setPage(newPage);
   };
   useEffect(()=>{

    setMyUsers(old => users);

   },[users]);

    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle className="cornerText"> Recherche Utilisateurs  </DialogTitle>
        <DialogContent id="dialogContent" >
        <Card className="searchCard" variant="outlined">
          

        <CardContent>
    
        <p  className="cornerText">Recherche Global  </p>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
              <Typography className="littleTitle" sx={{ fontSize: 14 }} gutterBottom>
              Recherche :
            </Typography>
            <MyInput value={search} onChange={(event) =>{setSearch((old)=> (event.target.value))}} 
            placeholder="Recherche" 
      />
              </Grid>
          </Grid>
      
        </CardContent>
        </Card>
        <br/> 
<TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">ID</TableCell>
     

    </TableRow>
  </TableHead>

  <TableBody>
  {myUsers
  .filter(
      
      user => (
        (user.id).toLowerCase()?.includes((search).toLowerCase()) 
       

  ))
.slice(page * 10, page * 10 + 10)
.map(user => (
<TableRow
 key={user.id}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
onClick={
  () => {
   handleSubmit({
    userId:user.id ,
   });
   handleClose();
  }
   }
>
<TableCell component="th" scope="row" align="left">{user.id}</TableCell>


</TableRow>
))}
     
  </TableBody>
  <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[10]}
          colSpan={3}
          count={
            myUsers
  .filter(
      
      user => (
        (user.id).toLowerCase()?.includes((search).toLowerCase()) 
       

  )).length
            }
          rowsPerPage={10}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
        />
      </TableRow>
    </TableFooter>
</Table>

</TableContainer>
        </DialogContent>
      </Dialog>
    )
};
export default UserSearchModal;
