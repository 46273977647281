
import "./SideNavbarMenuStyle.css";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";


import logo from "../../assets/logo2.png";
import logo_without_typo from "../../assets/logo1.svg";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//MUI ICONS
import MenuIcon from "@mui/icons-material/Menu";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';

import { useDispatch, useSelector } from "react-redux";
import { saveLogoutInLocalStorage } from "../../helpers/constants";
import { logout } from "../../redux/auth/authactions";


const SideNavbarMenu = () => {
  const dispatch=useDispatch();
  const history = useNavigate();
  const decodedJWT=useSelector(state => state.decodedJWT );
  const freeTokenFromSessionStorage = () => {
    dispatch(logout);
    saveLogoutInLocalStorage();
    history("/login");
  };

  var [show, setShow] = useState(false);

  const showMenu = () => {
    setShow((show = !show));
  };

  const closeMenu = () => {
    setShow((show = !show));
  };

  const menuEtat = show ? "show" : "hide";
  return (
    <div>
      <MenuIcon onClick={showMenu} className="menu-icon-responsive" />
      <div className={`sidebar-responsive ${menuEtat}`}>
        <span onClick={closeMenu} className="close-btn">
          &#10005;
        </span>
        <div className="imageLogo" >
          <img
            className="imageGrand-responsive"
            src={logo}
            style={{ width: "100px", marginTop: "20px",height:"100px" }}
          />
        </div>
        <Link to="/">
          <div style={{ display: "flex" }}>
            <DashboardOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">DashBoard</p>
          </div>
        </Link>

       <Link to="/foodManagement">
            <div style={{display: "flex"}}>
                <StoreOutlinedIcon className='menu-all-icons-responsive'/>
                <p className='menu-text-responsive'>FoodManagement</p>
            </div>
        </Link>
        <Link to="/mealManagement">
            <div style={{display: "flex"}}>
                <FastfoodOutlinedIcon className='menu-all-icons-responsive'/>
                <p className='menu-text-responsive'>MealManagement</p>
            </div>
        </Link>
      
        { (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Cook" ) &&
        <Link to="/gestionCaisse">
          <div style={{ display: "flex" }}>
            <AttachMoneyOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Caisse</p>
          </div>
        </Link>
       }
       <Link to="/orders" >
          <div style={{ display: "flex" }}>
            <RestoreOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Orders</p>
          </div>
        </Link>
        <Link to="/supplierOrders">
          <div style={{ display: "flex" }}>
            <ShoppingBagOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Supplier Orders</p>
          </div>
        </Link>
        <Link to="/locals">
          <div style={{ display: "flex" }}>
            <GroupOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Locals</p>
          </div>
        </Link>
        <Link to="/personnel">
          <div style={{ display: "flex" }}>
            <GroupOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Personnel</p>
          </div>
        </Link>
            
        { (decodedJWT.Role=="SuperAdmin") &&
       <Link to="/users">
       <div style={{ display: "flex" }}>
         <GroupOutlinedIcon className="menu-all-icons-responsive" />
         <p className="menu-text-responsive">Users</p>
       </div>
     </Link>
       }
      
        <Link to="/promoCodes">
          <div style={{ display: "flex" }}>
            <GroupOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Promo Codes</p>
          </div>
        </Link>
        <Link to="/news">
          <div style={{ display: "flex" }}>
            <GroupOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">News</p>
          </div>
        </Link>
        <Link to="/" onClick={freeTokenFromSessionStorage}>
          <div style={{ display: "flex" }}>
            <LogoutOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Déconnexion</p>
          </div>
        </Link>
        {/* <Link to="/" onClick={freeTokenFromSessionStorage}>
          <div style={{ display: "flex" }}>
            <LogoutOutlinedIcon className="menu-all-icons-responsive" />
            <p className="menu-text-responsive">Déconnexion</p>
          </div>
        </Link> */}
      </div>
      <div className="sidebar">
        <div className="imageLogo">
          <img className="imageGrand" src={logo} style={{ width: "50%" }} />
          <img
            className="imageSmall"
            src={logo_without_typo}
            style={{ width: "25px" }}
          />
        </div>
        <Link to="/dashBoard">
          <div style={{ display: "flex", alignItems: "center"}}>
            <DashboardOutlinedIcon className="menu-icon" />
            <p className="menu-text">DashBoard</p>
          </div>
        </Link>

       <Link to="/foodManagement">
          <div style={{ display: "flex", alignItems: "center" }}>
            <StoreOutlinedIcon className="menu-icon" />
            <p className="menu-text">FoodManagement</p>
          </div>
        </Link>
        <Link to="/mealManagement">
          <div style={{ display: "flex", alignItems: "center" }}>
            <FastfoodOutlinedIcon className="menu-icon" />
            <p className="menu-text">MealManagement</p>
          </div>
        </Link>
        { (decodedJWT.Role=="Admin" || decodedJWT.Role=="SuperAdmin" || decodedJWT.Role=="Cook" ) &&
        <Link to="/gestionCaisse" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <AttachMoneyOutlinedIcon className="menu-icon" />
            <p className="menu-text">Caisse</p>
          </div>
        </Link>
}

      <Link to="/orders" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <RestoreOutlinedIcon className="menu-icon" />
            <p className="menu-text">Orders</p>
          </div>
        </Link>
        <Link to="/supplierOrders">
          <div style={{ display: "flex", alignItems: "center" }}>
            <ShoppingBagOutlinedIcon className="menu-icon" />
            <p className="menu-text">Supplier Orders</p>
          </div>
        </Link>
        <Link to="/locals" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <StorefrontOutlinedIcon className="menu-icon" />
            <p className="menu-text">Locals</p>
          </div>
        </Link>
        <Link to="/personnel" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <StorefrontOutlinedIcon className="menu-icon" />
            <p className="menu-text">Personnel</p>
          </div>
        </Link>
        { (decodedJWT.Role=="SuperAdmin") &&
           <Link to="/users" >
           <div style={{ display: "flex", alignItems: "center" }}>
             <StorefrontOutlinedIcon className="menu-icon" />
             <p className="menu-text">Users</p>
           </div>
         </Link>
       }
  

        <Link to="/promoCodes" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <StorefrontOutlinedIcon className="menu-icon" />
            <p className="menu-text">Promo Codes</p>
          </div>
        </Link>
        <Link to="/news" >
          <div style={{ display: "flex", alignItems: "center" }}>
            <StorefrontOutlinedIcon className="menu-icon" />
            <p className="menu-text">News</p>
          </div>
        </Link>
 
        <Link to="/" onClick={freeTokenFromSessionStorage}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <LogoutOutlinedIcon className="menu-icon" />
            <p className="menu-text">Déconnexion</p>
          </div>
        </Link>



        {/* <Link to="/" onClick={freeTokenFromSessionStorage}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <LogoutOutlinedIcon className="menu-icon" />
            <p className="menu-text">Déconnexion</p>
          </div>
        </Link> */}
      </div>
    </div>
  );
};
export default SideNavbarMenu;
