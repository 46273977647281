import {Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Button,Slide,
  Card,CardContent, Grid ,Typography,TableContainer,Table
  ,TableHead,TableRow,TableCell,TableBody,TablePagination,TableFooter,
  Paper,Select,MenuItem,FormControl,InputLabel } from "@mui/material";
import InputUnstyled from '@mui/base/InputUnstyled';
import React, { useState,useEffect } from "react";
import "./index.css";




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const ExploreModal=({open,handleClose,order}) => {

  const [myOrder,setMyOrder]=useState({});


 

 useEffect(
   () => {
    console.log("SelectedOrder");
    console.log(order);
 
    setMyOrder(old => order);


   },
   [order]
 );



 
  
 
  // const freeTokenFromSessionStorage = () => {
  //   dispatch(logout);
  //   saveLogoutInLocalStorage();
  //   history("/login");
  // };
    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullScreen="true"

      
      >
        <DialogTitle className="cornerText"> Order Details : {myOrder.id} </DialogTitle>
        <DialogContent className="orderExplorContainer" >
        <TableContainer className="tableContainerStyle"  component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="simple table">
    
  <TableHead>
    <TableRow>
      <TableCell className="filtertitle" align="left">Name</TableCell>
      <TableCell className="filtertitle" align="left">Macros</TableCell>
      <TableCell className="filtertitle" align="left">Calories</TableCell>
      <TableCell className="filtertitle" align="left">Recipe</TableCell>
      <TableCell className="filtertitle" align="left">Quantity</TableCell>
      </TableRow>
  </TableHead>

{
(myOrder!=null &&  myOrder.orderMeals!=null) &&
<TableBody>
{myOrder.orderMeals
.map(orderMealWithQuantity => (
<TableRow
key={orderMealWithQuantity.meal.id+"q"+orderMealWithQuantity.quantity+"p"+orderMealWithQuantity.meal.protein+"c"+orderMealWithQuantity.meal.carbs+"f"+orderMealWithQuantity.meal.fat}
sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell align="left">{orderMealWithQuantity.meal.id}</TableCell>
<TableCell align="left">Prot:{orderMealWithQuantity.meal.protein}g,<br/>Carbs:{orderMealWithQuantity.meal.carbs},<br/>Fat:{orderMealWithQuantity.meal.fat}</TableCell>
<TableCell  align="left"> {orderMealWithQuantity.meal.calories} Kcal</TableCell>
<TableCell  align="left">{orderMealWithQuantity.meal.recipe!=null && 
    
      orderMealWithQuantity.meal.recipe.map(
        (element) => (
          <div key={element.foodId}>food : <span className="bigTealText">{element.foodId}</span>, quantity : <span className="bigTealText">{Number.parseFloat(element.quantity).toFixed(0)} g </span>, quantityCooked : <span className="bigTealText">{Number.parseFloat(element.quantityCooked).toFixed(0)}g</span></div>
        )
      )
    
}</TableCell>
<TableCell  align="left"><span className="bigRedText">{orderMealWithQuantity.quantity}</span></TableCell>



</TableRow>
))}
   
</TableBody>
}
 

</Table>

</TableContainer>
  
        </DialogContent>
        <DialogActions>
          <Button id="fermerButton" onClick={handleClose}>Fermer</Button>
    
        </DialogActions>
      </Dialog>
    )
};

export default ExploreModal;
