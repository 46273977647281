import { Route, Routes, Navigate } from "react-router-dom";


import Layout from "../layout";

import { useSelector } from "react-redux";

//Pages
import Login from "../pages/Login";
import Dashboard from "../pages/dashBoard";
import FoodManagement from "../pages/foodManagement";
import MealManagement from "../pages/mealManagement";
import Orders from "../pages/orders";
import Personnel from "../pages/gestionpersonnel";
import Caisse from "../pages/gestioncaisse";
import Locals from "../pages/gestionlocal";
import Achat from "../pages/gestionachat";
import News from "../pages/news";
import UsersPage from "../pages/userspage";
import PromoCodes from "../pages/promoCodes"

const MainRoutes = ()=>{

  const decodedJWT=useSelector(state => state.decodedJWT );
  if(decodedJWT?.Role=="SuperAdmin")
 return (
 <Routes>
    <Route path="/dashBoard" exact element={<Layout title="DashBoard" ><Dashboard/> </Layout>} />
    <Route path="/foodManagement" exact element={<Layout title="FoodManagement" ><FoodManagement/> </Layout>} />
    <Route path="/mealManagement" exact element={<Layout title="MealManagement" ><MealManagement/> </Layout>} />
    <Route path="/gestionCaisse" exact element={<Layout title="gestion caisse" ><Caisse/> </Layout>} />
    <Route path="/orders" exact element={<Layout title="Orders" ><Orders/> </Layout>} />
    <Route path="/personnel" exact element={<Layout title="Personnel" ><Personnel/> </Layout>} />
    <Route path="/users" exact element={<Layout title="Users" ><UsersPage/> </Layout>} />
    <Route path="/locals" exact element={<Layout title="Locals" ><Locals/> </Layout>} />
    <Route path="/supplierOrders" exact element={<Layout title="Supplier Orders" ><Achat/> </Layout>} />
    <Route path="/promoCodes" exact element={<Layout title="Promo Codes" ><PromoCodes/> </Layout>} />

    <Route path="/news" exact element={<Layout title="News" ><News/> </Layout>} />
    <Route path="*" exact element={<Layout title="Dashboard" ><Dashboard/> </Layout>} />


  </Routes>)
  else   
  return (
  <Routes>
     <Route path="/dashBoard" exact element={<Layout title="DashBoard" ><Dashboard/> </Layout>} />
     <Route path="/gestionCaisse" exact element={<Layout title="gestion caisse" ><Caisse/> </Layout>} />
     <Route path="/foodManagement" exact element={<Layout title="FoodManagement" ><FoodManagement/> </Layout>} />
     <Route path="/orders" exact element={<Layout title="Orders" ><Orders/> </Layout>} />

     <Route path="*" exact element={<Layout title="Dashboard" ><Dashboard/> </Layout>} />
 
   </Routes>)
 

};
//  <Route element={<Layout/>}>
export default MainRoutes;
